import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import apiCallsService from '../services/apiCalls.service';

const Wrapper = styled(motion.div)`
	position: fixed;
	right: 6%;
	top: 12.4rem;
	z-index: 10;
	width: 100%;
	max-width: 26.7%;
	min-height: 79.5vh;
	@media screen and (max-width: 1100px) {
		position: absolute;
		top: 1.2rem;
		right: 0%;
		max-width: 100%;
		height:120vh;
		display:flex;
		flex-direction: column;
		align-items: center;

	}
	background:#CECECE;
`;

const WrapperTop = styled.div`
	margin-top: 1.2rem;
	position: relative;
	width:100%;
	@media screen and (max-width: 1100px) {
		width:100%;
	}
`;

const Info = styled.div`
	display: flex;
	align-items: center;
	gap: 3.9rem;
	font-family: Fira Code;
	font-size: ${props => props.theme.fontMain};
	font-weight: 600;
	line-height: 1.8rem;
	background: #CECECE;
	color: #191919;
	padding: 1rem 0 0.8rem 1rem;

	& > span {
		font-weight: 600;
		font-size: ${props => props.theme.fontsm};
		line-height: 2.5rem;
		color: #191919;
	}
	@media screen and (max-width: 1100px) {
		padding: 2rem 0 1.7rem 3.2rem;
		& > span {
			font-size: 2.1rem;
		}
		@media screen and (max-width: 567px) {
			& > span {
				font-size: 1.9rem;
			}
		}
	}
`;

const Close = styled.div`
	position: absolute;
	right: 4.8rem;
	top: 0.7rem;
	width: 3rem;
	height: 3rem;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-family: Fira Code;
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 1.8rem;
	color: #000000;
	& > span {
		width: 100%;
		height: 2px;
		background: ${props => props.theme.colorGray};
		transform: translateX(0.8rem) rotate(-45deg);
		transition: all 0.2s ease;
		&:last-child {
			transform: translateX(-0.7rem) rotate(45deg);
		}
	}
	&:hover {
		& > span {
			background: #fff;
		}
	}
	@media screen and (max-width: 1100px) {
		width: 4rem;
		height: 4rem;
		top: 0.7rem;
		& > span {
			transform: translateX(1.2rem) rotate(-45deg);
		}
	}

	@media screen and (max-width: 1100px) {
		width: 4rem;
		height: 4rem;
		top: 1.2rem;
		font-size: 1.7rem;
		line-height: 2.2rem;
		right: 6.2rem;
		& > span {
			transform: translateX(1.2rem) rotate(-45deg);
		}
	}
`;

const WrapperMain = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 3rem;
	width: calc(100% - 1.9rem);
	height: 62rem;
	margin: 0 0 0 1.2rem;

	@media screen and (max-width: 1100px) {
		max-width: 90%;
		justify-content: flex-start;
		height: 65rem;
		margin: 0 0 0 0;
	}
`;

const WrapperLeft = styled.div`
	max-width: 16.8rem;
	width: 100%;
	padding: 0.7rem 2.3rem;
	background: rgba(130, 130, 130, 0.2);
	@media screen and (max-width: 1100px) {
		max-width: 24rem;
	}
`;

const Image = styled.img`
	margin-top:7.8rem;
	max-width: 42rem;
	height: 42rem;
	object-fit: contain;
	object-position: center;

	@media screen and (max-width: 1100px) {
		margin: auto;
		max-width: 48rem;
		height: 47rem;
		margin-top: 2.9rem;

	}
`;

const WrapperInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 0.9rem;
	width: 100%;
	@media screen and (max-width: 1100px) {
		gap: 1rem;
	}
`;
const InfoLine = styled.div`
	margin-left: 0.2rem;
	display: flex;
	justify-content: space-between;
	width: calc(100% - 3rem);
	font-weight: 400;
	font-size: 1.2rem;
	line-height: 1.8rem;
	color: #191919;
	font-family: Fira Code;
	@media screen and (max-width: 1100px) {
		width:100%;
	}
`;
const Group = styled.div``;
const Title = styled.div`
	font-weight: 600;
	font-size: 1.2rem;
	line-height: 1.8rem;
	color: #191919;
	font-family: Fira Code;
	@media screen and (max-width: 1100px) {
		font-size: 1.7rem;
		line-height: 2.1rem;
	}
`;
const Text = styled.div`
	font-weight: 300;
	font-size: ${props => props.theme.fontMain};
	line-height: 1.7rem;
	color: #282828;
	text-transform: uppercase;
	@media screen and (max-width: 1100px) {
		font-size: 1.7rem;
		line-height: 2.1rem;
		max-width: 100%;
	}
`;

const Price = styled.div`
	align-self: flex-end;

	& > span {
		font-family: Fira Code;
		font-weight: 600;
		font-size: ${props => props.theme.fontsm};
		line-height: 1.8rem;
		color: #282828;
		@media screen and (max-width: 1100px) {
			font-size: 1.8rem;
			line-height: 2.2rem;
			max-width: 100%;
		}
	}
`;
const WrapperBottom = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1.5rem 0 1.3rem;
	height: 4.6rem;
	width: calc(100% - 4.2rem);
	background: ${props => props.theme.colorMain};
	border: 1px solid transparent;
	margin: 0.9rem 3.5rem 1.5rem 1.2rem;
	text-align: center;
	cursor: pointer;
	transition: all 0.2s ease;
	filter: ${props => (props.inStock === 0 ? 'blur(1px)' : 'unset')};
	pointer-events: ${props => (props.inStock === 0 ? 'none' : 'auto')};

	& > span {
		font-weight: 450;
		font-size: ${props => props.theme.fontMain};
		line-height: 1.8rem;
		color: #adadad;
		text-align: center;
		text-transform: uppercase;
		transition: all 0.2s ease;
	}
	&:hover {
		background: transparent;
		border: 1px solid ${props => props.theme.colorMain};
		& > span {
			color: ${props => props.theme.colorMain};
		}
	}
	@media screen and (max-width: 1100px) {
		height: 6.2rem;
		width: 90%;
		margin: 3.3rem auto;
		& > span {
			font-size: 1.8rem;
			line-height: 2.2rem;
		}
	}
	@media screen and (max-width: 567px) {
		height: 6rem;
	}
`;

const GearPrice = styled.div`
	display:flex;
	justify-content: flex-end;
	width: 50%;
	height: 100%;
	text-align: right;

	font-family: Fira Code;
	font-weight: 600;
	font-size: ${props => props.theme.fontsm};
	line-height: 1.8rem;
	color: #282828;
	@media screen and (max-width: 1100px) {
		font-size: 1.8rem;
		line-height: 2.2rem;
		max-width: 100%;
	}

`;




export const ModalProdAdd = ({ showmodalProduct, setShowmodalProduct }) => {
	const [currentProduct, setCurrentProduct] = useState([]);
	const cart = useSelector(state => state.cart);
	const navigate = useNavigate();
	const [model, setModel] = useState([]);
	const [color, setColor] = useState([]);
	const [size, setSize] = useState([]);

	const [mainEvent, setMainEvent] = useState({});



	useEffect(() => {
		cart && setCurrentProduct(cart.products[cart.products.length - 1]);
		try{
			setModel(cart.products[cart.products.length - 1].model)
			setColor(cart.products[cart.products.length - 1].description.color)
			setSize(cart.products[cart.products.length - 1].description.sizeFull)
		}
		catch(e){}

		try{
			async function fetchEventData() {
				const events_response = await apiCallsService.getEventsRequest();
				setMainEvent(events_response)

			}
			fetchEventData()
		}catch(e){}

	}, [cart]);


	const TotalPrice = ({price, priceWithDiscount = undefined, mainEventDiscount = undefined}) => {
		let priceWithEventDiscount = undefined;

		if (mainEventDiscount)
			priceWithEventDiscount = Math.floor(price*(100-mainEventDiscount)/100)

		if (priceWithDiscount!=undefined & priceWithDiscount!== '' & priceWithDiscount!== 0 & mainEventDiscount!=undefined) {
			if (priceWithEventDiscount < priceWithDiscount) {
				return (
						<GearPrice>${priceWithEventDiscount*currentProduct.quantity} <div style={{textDecoration: 'line-through', opacity: '0.5', marginLeft:'1rem'}}>${price*currentProduct.quantity}</div></GearPrice>
				)
			}
			else {
				return (
						<GearPrice>${priceWithDiscount*currentProduct.quantity} <div style={{textDecoration: 'line-through', opacity: '0.5', marginLeft:'1rem'}}>${price*currentProduct.quantity}</div></GearPrice>
				)
			}
		}
		if (priceWithDiscount) {
			return (
					<GearPrice>${priceWithDiscount*currentProduct.quantity} <div style={{textDecoration: 'line-through', opacity: '0.5', marginLeft:'1rem'}}>${price*currentProduct.quantity}</div></GearPrice>
			)
		}
		if (mainEventDiscount) {
			return (
					<GearPrice>${priceWithEventDiscount*currentProduct.quantity} <div style={{textDecoration: 'line-through', opacity: '0.5', marginLeft:'1rem'}}>${price*currentProduct.quantity}</div></GearPrice>
			)
		}
		return (
				<GearPrice>${price*currentProduct.quantity}</GearPrice>
		)
	}

	return (
		<Wrapper
			showmodal={showmodalProduct ? 1 : 0}
			initial={{ x: 750 }}
			animate={{ x: 0 }}
			transition={{ delay: 0.1, ease: 'easeOut' }}
			exit={{ x: 750 }}
		>
			{currentProduct && (
				<>
					<WrapperTop>
						<Info>
							<span>
								ITEM ADDED OUR CART
								{/* {currentProduct.quantity}/
								{currentProduct.description?.inStock} of set */}
							</span>
						</Info>
						<Close onClick={() => setShowmodalProduct(false)}>
								{'[CLOSE]'}
						</Close>
					</WrapperTop>
					<WrapperMain>
						<Image
							src={currentProduct?.imgMain?.replace('http:', 'https:')}
							alt={currentProduct.name}
						/>
						<WrapperInfo>
							<InfoLine>
								<Title>{currentProduct.name}</Title>
								<Price>
									<TotalPrice price={currentProduct.price} priceWithDiscount={currentProduct.priceWithDiscount}  mainEventDiscount={mainEvent.active ? mainEvent.discont : undefined} />
								</Price>
							</InfoLine>
							<InfoLine>
								<Text>{model} / {color} / {size}</Text>
							</InfoLine>
							<InfoLine style={{width: '67%'}}>
								<Text>{'QUANTITY'}</Text>
								<Text>{currentProduct.quantity}</Text>
							</InfoLine>

						</WrapperInfo>
					</WrapperMain>
				</>
			)}
				<WrapperBottom onClick={()=> navigate("/order")}>
					<span>VIEW CART</span>
				</WrapperBottom>
		</Wrapper>
	);
};
