import { motion } from 'framer-motion';
import React, { useState } from 'react';
import styled from 'styled-components';
import heroBg from '../../assets/hero-bg.svg';
import heroBgTab from '../../assets/hero-bg-tab.svg';
import augmenBgFirst from '../../assets/augmen-bg2.svg';
import { useEffect } from 'react';
import itemImgMob from '../../assets/items/item1-mob.svg';
import { useSelector } from 'react-redux';

const HeroWrapper = styled.div`
	border-left: 0px solid ${props => props.theme.colorBorder};
	width: 10.1rem;
	height: 100%;

	@media screen and (max-width: 1100px) {
		margin-left: 0.5rem;
		max-width: 100%;
		width: 13%;
		border-left: unset;
	}
	@media screen and (max-width: 567px) {
		width: 28%;
		//border-right: 1px solid ${props => props.theme.colorBorder};
		height: auto;
	}
`;

const HeroTop = styled.div`
	height: 7.8rem;
	//border-bottom: 1px solid ${props => props.theme.colorBorder};
	@media screen and (max-width: 1100px) {
		display: none;
	}
`;

const MenuWrapper = styled.div`

	transition: all 2.5s ease;

	@media screen and (max-width: 1100px) {

	}
`;

const Menu = styled.div`
	height: 7.8rem;
	display:flex;
	align-items:center;
	font-family: Fira Code;
	font-size: 1.2rem;
	font-weight: 600;
	line-height: 1.8rem;
	text-align: left;
	padding-left: 2.8rem;
	width: 12rem;
	margin-right: -3rem;
	color: #000000;
	cursor: pointer;

	@media screen and (max-width: 1100px) {
		padding-left: 2.5rem;
		font-size: ${props => props.theme.fontMob};
		font-weight: 700;
		line-height: 2rem;
	}
`;

const MenuModal = styled.div`
	position: absolute;
	left: 4.6rem;
	top: 5rem;
	height: 7rem;
	align-items:start;
	font-family: Fira Code;
	font-size: 1.2rem;
	font-weight: 600;
	line-height: 1.8rem;
	text-align: left;
	background: #CECECE;
	transition: all 0.5s ease;

	overflow:hidden;

	z-index: 6;
	padding-left: 1.7rem;
	padding-top: 3rem;
	width: 12rem;
	color: #000000;

	user-select: none;

	@media screen and (max-width: 1100px) {

		left: 0.3rem;
		top: 13.9rem;
		transition: all 0.35s ease;
	}

`;

const MenuContr = styled.div`
	display:flex;
	align-items:start;
	justify-content:space-between;
	width: 21.5vw;
	margin-left:1rem;

	font-family: Fira Code;
	font-size: 1.2rem;
	font-weight: 600;
	line-height: 1.8rem;
	text-align: left;

	@media screen and (max-width: 1100px) {

		font-size: ${props => props.theme.fontMob};
		font-weight: 700;
		line-height: 1.7rem;
	}

`;

const MenuClose = styled.div`

	margin-right: -5vw;
	font-family: Fira Code;
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 1.8rem;
	text-align: left;

	@media screen and (max-width: 1100px) {
		position:absolute;
		right:7.2rem;
		margin-top: 5.4rem;
		font-size: ${props => props.theme.fontMob};
		font-weight: 500;
		line-height: 2rem;
		//margin-right: -70vw;
	}

`;

const MenuCategoryTitle = styled.div`

	margin-top: 3.14rem;
	margin-left: 2.5rem;
	width:100%;
	font-family: Fira Code;
	font-size: 1.2rem;
	font-weight: 600;
	line-height: 1.8rem;
	text-align: left;

	@media screen and (max-width: 1100px) {
		font-size: ${props => props.theme.fontMob};
		font-weight: 700;
		line-height: 3rem;
	}
`;

const MenuCategoryContainer = styled.div`

	margin-top: 1.3rem;
	margin-left: 2.5rem;
	width:100%;
	font-family: Fira Code;
	font-size: 1.2rem;
	font-weight: 600;
	line-height: 1.8rem;
	text-align: left;
`;
const MenuCategoryItem = styled.div`

	width:100%;
	font-family: Fira Code;
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 2.16rem;
	text-align: left;
	cursor:pointer;

	@media screen and (max-width: 1100px) {
		font-size: ${props => props.theme.fontMob};
		font-weight: 500;
		line-height: 3rem;
	}
`;

const Image = styled.img`
	max-width: 100%;
	object-fit: contain;
	object-position: center;
	height: 100%;
`;

const HeroItemsContent = styled.div`
	position: relative;
	background-image: url(${heroBg});
	background-size: contain;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 5.1rem;
	height: 5.1rem;
	@media screen and (max-width: 1100px) {
		background-image: url(${heroBgTab});
		background-size: contain;
		background-repeat: no-repeat;
		width: 6rem;
		height: 6rem;
	}
	@media screen and (max-width: 567px) {
		width: 7.5rem;
		height: 7.5rem;
	}
`;

const HeroBlock = styled.div`
	max-width: 11.8rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	overflow-y: auto;
	padding: 0.4rem 0 0 2.2rem;
	gap: 1.3rem;
	&::-webkit-scrollbar {
		width: 0.3rem;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 12px;
		background-color: ${props => props.theme.colorBlack};
	}
	@media screen and (max-width: 1700px) {
		gap: 1.2rem;
	}
	@media screen and (max-width: 1100px) {
		gap: 1rem;

		& > div {
			&:last-child {
				${Image} {
					//max-width: 1.2rem;
				}
				${HeroItemsContent} {
					//background-image: url(${augmenBgFirst});
					//height: 12.5rem;
				}
			}
		}
	}
	@media screen and (max-width: 567px) {
		gap: 1.5rem;
		& > div {
			&:last-child {
				${Image} {
					//max-width: 1.5rem;
				}
				${HeroItemsContent} {
					//height: 15.5rem;
				}
			}
		}
	}
`;

const HeroMain = styled.div`
	display: flex;
	flex-direction: column;
	height: calc(100% - 7.8rem);
	padding: 0rem 0rem 1rem;
	@media screen and (max-width: 1100px) {
		height: 100%;
		padding-top: 0.6rem;
	}
	@media screen and (max-width: 567px) {
		padding-top: 0;
	}
`;

const HeromainView = styled.div`
	font-weight: 400;
	font-size: 1.2rem;
	line-height: 1.8rem;
	text-align: center;
	text-transform: uppercase;
	@media screen and (max-width: 1100px) {
		font-size: 1.3rem;
	}

	@media screen and (max-width: 567px) {
		font-size: 1.4rem;
		text-align: center;
		padding-top: 0.9rem;
	}
`;

const GearLine = styled.div`
	position: absolute;
	left: 3px;
	top: -1.2px;
	background: ${props => props.theme.colorMain};
	height: 3px;
	max-width: 2rem;
	width: 100%;
	transition: all 0.2s ease;
	opacity: ${props => (props.activeelement ? '0' : '1')};
`;

const HeroItemsAside = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.3rem;
	margin-right: 0.2rem;
	transition: all 0.2s ease;
	transform: ${props =>
		props.activeelement ? 'translateX(-0.4rem)' : 'unset'};


	//z-index:6;

	& > span {
		transform: rotate(-180deg);
		writing-mode: vertical-lr;
		color: ${props => props.theme.colorMain};
		font-size: 6px;
		text-transform: uppercase;
		@media screen and (max-width: 1100px) {
			font-size: 8px;
		}
		@media screen and (max-width: 567px) {
			font-size: 6px;
		}
	}
`;

const HeroAsideLine = styled.div`
	position: absolute;
	left: -0.4rem;
	top: 0%;
	content: '';
	width: 0.8rem;
	height: 100%;
	background: ${props => props.theme.colorMain};
	transition: all 0.2s ease;
	opacity: ${props => (props.activeelement ? '1' : '0')};
`;

const HeroItems = styled(motion.div)`
	position: relative;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	transition: all 0.2s ease;
	opacity: ${props => (props.activeelement ? '1' : '0.5')};
`;

const Lock = styled.svg`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(calc(-50% + 0.6rem), -50%);
	width: 1.2rem;
	height: 1.7rem;
	z-index: 3;
	& > rect {
		transform: translateY(5px);
		width: 1.2rem;
		height: 1.7rem;
	}
	& + ${HeroItems} {
		opacity: 0.15;
	}
	@media screen and (max-width: 1100px) {
		transform: translate(calc(-50% + 0.6rem), -50%);
	}
	@media screen and (max-width: 567px) {
		width: 2rem;
		height: 2.5rem;
		& > rect {
			width: 1.5rem;
			height: 1.3rem;
		}
	}
`;

const HeroGroup = styled.div`
	position: relative;
	pointer-events: ${props => (props.lock ? 'none' : 'auto')};
	cursor: pointer;
	&:hover ${HeroItems} {
		opacity: 1;
	}
	&:hover ${GearLine} {
		opacity: 0;
	}
	&:hover ${HeroAsideLine} {
		opacity: 1;
	}
	&:hover ${HeroItemsAside} {
		transform: translateX(-0.4rem);
	}
`;

const Icon = styled.svg`
	width: 6px;
	height: 7px;
	fill: none;
`;

const CollectionTitle = styled.div`
	margin-top: 1.5rem;
	font-family: Fira Code;
	font-size: 1.2rem;
	font-weight: 600;
	line-height: 2.16rem;
	text-align: left;

	@media screen and (max-width: 1100px) {
		font-size: ${props => props.theme.fontMob};
		font-weight: 500;
		line-height: 3rem;
	}
`;



export const Hero = ({
	itemType,
	setItemType,
	activeelement,
	setActiveelement,
	showAllGear,
	setShowAllGear,
	collectionFilter,
	setCollectionFilter,
}) => {
	const [categories, setCategories] = useState([]);
	const [allProducts, setAllProducts] = useState([]);
	const [collection, setCollection] = useState([]);
	const category = useSelector(state => state.category);
	const data = useSelector(state => state.data);



	const [menuOpen, setMenuOpen] = useState(false);

	useEffect(() => {
		const filterProd = category?.filter(
			item =>  item.type !== 'items'
		);
		setCategories(filterProd);

		setAllProducts(data);
		let allcollections = []

		data.forEach((e) => {
			let res = e.availability?.split(',').map(s => s.trim());
			//console.log(e.availability)
		  allcollections.push(res)
		});
		let sumCollection = []
		allcollections.forEach((e) => {
			sumCollection= [...sumCollection, ...e]
		});
		setCollection([...new Set(sumCollection)]);


	}, [category]);

	const handleClick = (id, type) => {
		setActiveelement(id);
		setItemType(type);
		setShowAllGear(false);
		setCollectionFilter('')
		if (width < 1100) {
			setMenuOpen(false)
		}
	};

	const handleClickCollection = (collection) => {
		console.log(collection)
		setCollectionFilter(collection)
		setItemType();
		setActiveelement();
		setShowAllGear(false);
		if (width < 1100) {
			setMenuOpen(false)
		}
	};

	const useViewport = () => {
		const [width, setWidth] = useState(window.innerWidth);

		useEffect(() => {
			const handleWindowResize = () => {
				setWidth(window.innerWidth);
			};

			window.addEventListener('resize', handleWindowResize);
			return () => window.removeEventListener('resize', handleWindowResize);
		}, []);

		return { width };
	};

	const { width } = useViewport();

	const viewAllGear = () => {
		setShowAllGear(true);
		setActiveelement();
	};

	const DesktopComponent = () => {
		const [filterProduct, setFilterProduct] = useState([]);

		useEffect(() => {
			const result = categories?.filter(item => !item.mobile);

			result.sort((x, y) => {
				return x.lock - y.lock;
			});

			setFilterProduct(result);
		}, []);

		return (
			<HeroBlock>
				{filterProduct.map(item => (
					<HeroGroup
						key={item.id}
						lock={item.lock}
						onClick={() => handleClick(item.id, item.type)}
					>
						{item.lock && (
							<Lock viewBox='0 0 8.56 12.11'>
								<rect />
								<polygon points='8.56 3.74 6.69 3.74 6.69 1.84 1.87 1.84 1.87 3.74 0 3.74 0 0 8.56 0 8.56 3.74' />
							</Lock>
						)}
						<HeroItems
							activeelement={item.id === activeelement ? activeelement : null}
						>
							<HeroItemsAside
								activeelement={item.id === activeelement ? activeelement : null}
							>
								<Icon viewBox='0 0 3 4'>
									<path
										d='M1.71001 3.22H0V0.440002H2.78999V2.17999L1.71001 3.22Z'
										fill='#191919'
									/>
								</Icon>
								<span>{item.type}</span>
							</HeroItemsAside>
							<HeroItemsContent>
								<HeroAsideLine
									activeelement={
										item.id === activeelement ? activeelement : null
									}
								/>
								<GearLine
									activeelement={
										item.id === activeelement ? activeelement : null
									}
								/>
								<Image src={item.img?.replace('http:', 'https:')} alt='nerv' />
							</HeroItemsContent>
						</HeroItems>
					</HeroGroup>
				))}
			</HeroBlock>
		);
	};

	const MobileComponent = () => {
		const [filterProduct, setFilterProduct] = useState([]);

		useEffect(() => {
			const result = category?.filter(
				item => item.type === 'spec armr'
			);

			const sortCategories = [...categories, ...result].sort((x, y) => {
				return x.lock - y.lock;
			});

			setFilterProduct(sortCategories);
		}, []);

		return (
			<HeroBlock>
				{filterProduct.map(item => (
					<HeroGroup
						key={item.id}
						lock={item.lock}
						onClick={() => handleClick(item.id, item.type)}
					>
						{item.lock && (
							<Lock viewBox='0 0 8.56 12.11'>
								<rect />
								<polygon points='8.56 3.74 6.69 3.74 6.69 1.84 1.87 1.84 1.87 3.74 0 3.74 0 0 8.56 0 8.56 3.74' />
							</Lock>
						)}
						<HeroItems
							activeelement={item.id === activeelement ? activeelement : null}
						>
							<HeroItemsAside
								activeelement={item.id === activeelement ? activeelement : null}
							>
								<Icon viewBox='0 0 3 4'>
									<path
										d='M1.71001 3.22H0V0.440002H2.78999V2.17999L1.71001 3.22Z'
										fill='#191919'
									/>
								</Icon>
								<span>{item.type}</span>
							</HeroItemsAside>
							<HeroItemsContent>
								<HeroAsideLine
									activeelement={
										item.id === activeelement ? activeelement : null
									}
								/>
								<GearLine
									activeelement={
										item.id === activeelement ? activeelement : null
									}
								/>
								<Image
									src={item.type === 'items' ? itemImgMob?.replace('http:', 'https:') : item.img.replace('http:', 'https:')}
									alt='nerv'
								/>
							</HeroItemsContent>
						</HeroItems>
					</HeroGroup>
				))}
			</HeroBlock>
		);
	};

	const MyComponent = () => {

		const breakpoint = 1100;

		return width < breakpoint ? <MobileComponent /> : <DesktopComponent />;
	};




	return (
		<HeroWrapper>
			<HeroMain>
				<MenuWrapper>
					<Menu onClick={()=>{setMenuOpen(!menuOpen)}}>{'> MENU'} </Menu>
					<MenuModal style={menuOpen ? (width < 1100 ? {width: '100vw', height:'93%'} : {width: '30.3vw', height:'93%'}) : {width: '0vw', height:'93%'}}>
						<MenuContr>
							<div style={{cursor:'pointer'}} onClick={()=>{setMenuOpen(!menuOpen)}}>{'> MENU'}</div>
							<MenuClose style={{cursor:'pointer'}} onClick={()=>{setMenuOpen(!menuOpen)}}>{'[ CLOSE ]'}</MenuClose>
						</MenuContr>
						<MenuCategoryTitle>CATEGORY</MenuCategoryTitle>
						<MenuCategoryContainer>
							<MenuCategoryItem style={showAllGear ? {opacity: '1'} : {opacity: '0.5'}} onClick={()=> setShowAllGear(true)}>ALL PRODUCTS</MenuCategoryItem>
							{categories.map(item=>(

								<MenuCategoryItem style={itemType==item.type ? {opacity: '1'} : {opacity: '0.5'}} onClick={()=> handleClick(item.id, item.type)} key={`category_key_${item.id}`}>
								{item.type.toUpperCase()}
								</MenuCategoryItem>

							))}

							<CollectionTitle>{'COLLECTION'}</CollectionTitle>

							{collection.map(item=>(

								<MenuCategoryItem style={collectionFilter==item ? {opacity: '1'} : {opacity: '0.5'}} onClick={()=> handleClickCollection(item)}  key={`collection_key_${item}`}>
								{item.toUpperCase()}
								</MenuCategoryItem>

							))}
						</MenuCategoryContainer>
					</MenuModal>
				</MenuWrapper>
				<MyComponent />
			</HeroMain>
		</HeroWrapper>
	);
};
