export const theme = {
	fontxsm: '1.1rem',
	fontsm: '1.2rem',
	fontnm: '1.6rem',
	fontmd: '1.7rem',

	fontSmall: '1.1rem',
	fontMain: '1.2rem',
	fontBig: '1.4rem',
	fontWBig: '1.6rem',
	fontSuperBig: '1.7rem',
	fontMob: '1.7rem',
	fontMobBut: '1.8rem',

	colorMain: '#191919',
	colorGray: '#878787',
	colorBlack: '#000000',
	colorBorder: 'rgba(0,0,0,.2)',
};
