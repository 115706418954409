import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import apiCallsService from '../../services/apiCalls.service';
import { useForm } from 'react-hook-form';


const WrapperMain = styled.div`
	display: flex;
	flex-direction:column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 21.9rem;
	border-top: 1px solid ${props => props.theme.colorBorder};
	@media screen and (max-width: 1100px) {
		height: 100%;
		font-size: ${props => props.theme.fontMob};
		line-height: 2.2rem;
		font-weight: 500;
	}
`;



const PromoInfo = styled.div`
	display: flex;
	justify-content: left;
	align-items: center;
	width: 100%;
	min-height: 2rem;
	padding: 0rem 3.3rem;
	margin-top:1.3rem;
	margin-bottom:1rem;
	font-weight: 700;
	font-size: ${props => props.theme.fontBig};
	line-height: 1.8rem;
	@media screen and (max-width: 1100px) {

		padding: 0 2.2rem;
	}
	@media screen and (max-width: 567px) {

		padding: 0 0 0 0;
		margin-bottom:3.1rem;
	}
`;

const ContentPrice = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: top;
	padding: 0rem 3.3rem;
	width: 100%;
	height: 15.9rem;
	@media screen and (max-width: 1100px) {
		height: 100%;
		padding: 0rem 2.3rem;
	}
	@media screen and (max-width: 567px) {
		padding: 0 0 0 0;
	}
`;

const ContentPriceItem = styled.div`
	&:last-child {
		text-align: right;
		& > div {
			&:nth-child(2) {
				font-weight: 600;
			}
		}
	}
	& > div {
		font-weight: 400;
		font-size: 1.2rem;
		line-height: 1.8rem;
		color: ${props => props.theme.colorMain};
		margin-bottom: 0.2rem;

		&:last-child {
			font-weight: 600;
			margin-bottom: 0;
		}
	}
	@media screen and (max-width: 1100px) {
		font-size: ${props => props.theme.fontMob};
		line-height: 2.2rem;
		font-weight: 500;
	}
	@media screen and (max-width: 567px) {
		& > div {
			font-size: ${props => props.theme.fontMob};
			line-height: 2.2rem;
			font-weight: 500;
			margin-bottom: 0.5rem;
		}
	}
`;

const GreenCircle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 2rem;
	height: 2rem;
	background: #00FF00;
	border-radius:50%;
	margin-right:1rem;
`;



const PromoInputCustom = styled.div`
	margin-top: 1.2rem;
  width: 95%;
  display: flex;
  align-items:center;
  justify-content: space-between;

  @media screen and (max-width: 567px) {
		width: 95%;
  }
`;

const WrapperInput = styled.div`
  margin-top: -1rem;
  width: 95%;
  min-height: 4.5rem;
  border: 1px solid #A5A5A5;
	border-radius: 1px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content:center;

	transition: all 0.2s ease;

  @media screen and (max-width: 567px) {
  	width: 100%;
  }


`;

const MailInput = styled.input`
  margin-top: 0.7rem;
  width: 97%;
  min-height: 2rem;
  background-color: #CECECE;
  border-style: none;
  font-size: ${props => props.theme.fontMob};
  color: #191919;
	font-weight: 500;

	cursor: pointer;

  @media screen and (max-width: 567px) {
    margin-top: 2.4px;
    width: 280px;
    min-height: 28px;
    font-size: 16.8px;
  }

	&:hover,
	&:active,
	&:focus {
		${WrapperInput} {
			border: 1px solid #0f0f0f;
		}
	}

	&:focus ${WrapperInput} {
		border: 1px solid #0f0f0f;
	}

`;


const PromoTitle = styled.div`
	margin-bottom: -0.5rem;
  margin-left: 1%;
	padding: 0 0.8rem;
	//left: 2rem;
  //width: 9rem;
	height: 2.2rem;
  font-family: Fira Code;
  font-weight: 450;
	font-size: ${props => props.theme.fontMain};
	line-height: 1.5rem;
  text-align: center;
  z-index: 4;
  background-color: #CECECE;
  color: #000000;

  @media screen and (max-width: 567px) {
		margin-bottom: -0.7rem;
		//width: 11rem;
		font-size: ${props => props.theme.fontMob};
		line-height: 1.5rem;
  }
`;

const PromoWaterText = styled.div`
	margin-bottom: -4.5rem;
  margin-right: 2rem;
  width: 20rem;
	height: 1.5rem;
  font-family: Fira Code;
  font-weight: 450;
	font-size: 1.4rem;
	line-height: 1.6rem;
  text-align: center;
  z-index: 0;
  background-color: #CECECE;
  color: #000000;
	transition: all 0.7s ease;
	opacity: 0.3;
	@media screen and (max-width: 1100px) {
		margin-bottom: -5rem;
		font-size: 1.5rem;
		line-height: 1.6rem;
  }
  @media screen and (max-width: 567px) {
		margin-bottom: -6rem;
		font-size: 1.5rem;
		line-height: 1.6rem;
  }
`;

const InputWrapper = styled.div`
	position: relative;
	margin-top: 1.5rem;
	margin-bottom: 0.2rem;
	width: 95%;
	@media screen and (max-width: 1100px) {
		margin-bottom: 2.5rem;
		& > p {
			font-size: 1.2rem;
		}
	}
	@media screen and (max-width: 567px) {
		& > p {
			font-size: 1.4rem;
		}
	}
`;

const Input = styled.input`
	border: 1px solid ${props => props.theme.colorBorder};
	border-radius: 0;
	background: transparent;
	outline: none;
	width: 100%;
	height: 4rem;
	font-size: ${props => props.theme.fontsm};
	transition: all 0.2s linear;
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		box-shadow: inset 0 0 0 1000px #cecece;
	}

	&::placeholder {
		color: #000;
		font-size: 1.3rem;
		font-weight: 400;
		transform: translateX(1.7rem);
	}
	&:hover,
	&:active,
	&:focus {
		border: 1px solid #0f0f0f;
	}

	@media screen and (max-width: 1100px) {
		height: 6.2rem;
		font-size: 16px;
		&::placeholder {
			font-size: 16px;
		}
	}

	@media screen and (max-width: 567px) {
		&::placeholder {
			font-size: 10px;
		}
	}
`;

const InputPlaceHolder = styled.span`
	position: absolute;
	background: #cecece;
	padding: 0 0.8rem;
	left: 2rem;
	top: -0.8rem;
	font-weight: 300;
	font-size: 1.2rem;
	line-height: 1.7rem;
	color: ${props => (props.error ? '#ff0000' : '#000000')};
	text-transform: uppercase;
	@media screen and (max-width: 1100px) {
		font-size: ${props => props.theme.fontmd};
		line-height: 2.2rem;
		top: -1rem;

	}

	@media screen and (max-width: 1100px) {
		top: -0.9rem;
	}
`;

export const OrderPrice = ({mainEvent}) => {
	const cart = useSelector(state => state.cart);
	const [promoCodeData, setPromoCodeData] = useState({});
	const [promoCodeInput, setPromoCodeInput] = useState({});
	//console.log(cart)

	useEffect(() => {



		try {
			async function fetchPromoData() {
				let code = localStorage.getItem('promoCode');
				const responsePromoCode = await apiCallsService.getPromoRequest(JSON.parse(code));
				//console.log(responsePromoCode)
				if (responsePromoCode !== null) {
					setPromoCodeData(responsePromoCode)
					setValue('promoCode', responsePromoCode.code)

				}
			}
			fetchPromoData()
		}
		catch(e) {}

	}, []);


	const TotalResult = ({cart = undefined, mainEventDiscount = undefined, promoCodeData = undefined, dataReturn = 'total'}) => {
		let total_priceWithEventDiscount = 0;
		let total_priceWithPromoCode = 0;
		let total_priceWithProductDiscount = 0;
		let total_sum_price = 0;
		let best_discount = ''
		let promoDiscont = parseInt(promoCodeData.discont)

		let total_result = 0;

		if (cart) {
			cart.products.forEach((el , index)=>{
				if (el.priceWithDiscount!=='' & el.priceWithDiscount > 0) {
					total_priceWithProductDiscount+=(el.priceWithDiscount*el.quantity);
				}
				else {
					total_priceWithProductDiscount+=(el.price*el.quantity);
				}
				total_sum_price+=el.price*el.quantity;
			})
		}

		if (total_priceWithProductDiscount < total_sum_price)
			best_discount = 'ProductDiscount'

		if (mainEventDiscount !== undefined) {
			total_priceWithEventDiscount = total_sum_price*(100-mainEventDiscount)/100;
			if (total_priceWithEventDiscount < total_sum_price & total_priceWithEventDiscount < total_priceWithProductDiscount)
				best_discount = 'EventDiscount'
		}
		if (promoCodeData.discont !== undefined & promoCodeData?.maxUse > promoCodeData?.alreadyUse) {
			total_priceWithPromoCode = total_sum_price*(100-promoDiscont)/100;

			if (total_priceWithPromoCode < total_sum_price & total_priceWithPromoCode < total_priceWithProductDiscount ) {
				if (mainEventDiscount !== undefined) {
					if (total_priceWithPromoCode < total_priceWithEventDiscount) {
						best_discount = 'promoCodeDiscount'
					}
				}
				else {
					best_discount = 'promoCodeDiscount'
				}
			}
		}

		if (best_discount === '')
			total_result = total_sum_price

		if (best_discount === 'ProductDiscount')
			total_result = total_priceWithProductDiscount

		if (best_discount === 'EventDiscount')
			total_result = total_priceWithEventDiscount

		if (best_discount === 'promoCodeDiscount')
			total_result = total_priceWithPromoCode

		if (dataReturn === 'total')	{
			return (<div>${total_result}</div>)
		}
		if (dataReturn === 'subtotal')	{
			return (<div>${total_sum_price}</div>)
		}
		return (<div>${total_result}</div>)

	}


	const DescriptionDiscount = ({cart = undefined, mainEvent = undefined, promoCodeData = undefined}) => {
		let total_priceWithEventDiscount = 0;
		let total_priceWithPromoCode = 0;
		let total_priceWithProductDiscount = 0;
		let total_sum_price = 0;
		let best_discount = ''
		let promoDiscont = parseInt(promoCodeData.discont)

		let total_result = 0;

		let promoDiscountIsLow = false;


		if (cart) {
			cart.products.forEach((el , index)=>{
				if (el.priceWithDiscount!=='' & el.priceWithDiscount > 0) {
					total_priceWithProductDiscount+=(el.priceWithDiscount*el.quantity);
				}
				else {
					total_priceWithProductDiscount+=(el.price*el.quantity);
				}
				total_sum_price+=el.price*el.quantity;
			})
			//console.log(total_priceWithProductDiscount)
		}

		if (total_priceWithProductDiscount < total_sum_price)
			best_discount = 'ProductDiscount'

		if (mainEvent.discont  !== undefined) {
			total_priceWithEventDiscount = total_sum_price*(100-mainEvent.discont)/100;
			if (total_priceWithEventDiscount < total_sum_price & total_priceWithEventDiscount < total_priceWithProductDiscount) {
				best_discount = 'EventDiscount'
			}

		}
		if (promoCodeData.discont !== undefined) {
			total_priceWithPromoCode = total_sum_price*(100-promoDiscont)/100;

			if (total_priceWithPromoCode < total_sum_price & total_priceWithPromoCode < total_priceWithProductDiscount ) {
				if (mainEvent.discont !== undefined) {
					if (total_priceWithPromoCode < total_priceWithEventDiscount) {
						best_discount = 'promoCodeDiscount'
					}
					else {
						promoDiscountIsLow = true;
					}
				}
				else {
					best_discount = 'promoCodeDiscount'
				}
			}
			else {
				promoDiscountIsLow = true;
			}
		}

		if (best_discount === '')
			total_result = ''

		if (best_discount === 'ProductDiscount')
			total_result = `PLEASE NOTE: YOU'VE ALREADY SCORED THE BEST ITEM DEAL! THIS DISCOUNT IS THE BIGGEST WE CAN OFFER`

		if (best_discount === 'EventDiscount') {
			if (promoDiscountIsLow) {
				total_result = `PLEASE NOTE: YOU'VE ALREADY SCORED THE BEST DEAL! THIS DISCOUNT IS THE BIGGEST WE CAN OFFER.`
			}
			else {
				total_result = `PLEASE NOTE: GLOBAL DISCOUNT EVENT IS NOW LIVE. ONLY THE HIGHEST DISCOUNT WILL BE APPLIED.`
				//total_result = `${mainEventDiscount}% event discount applied. Note, different discounts do not add up, the largest one works.`
			}

		}


		if (best_discount === 'promoCodeDiscount') {
			if (promoCodeData.maxUse > promoCodeData.alreadyUse) {
				total_result = `YOUR DISCOUNT HAS BEEN APPLIED.`
				//total_result = `${promoDiscont}% promoCode discount applied. Note, different discounts do not add up, the largest one works.`
			}
			else {
				total_result = `WE'RE SORRY, BUT THIS PROMO CODE HAS EXPIRED. KEEP AN EYE OUT FOR OUR FUTURE OFFERS.`
			}
		}


		return (
			<>
				<GreenCircle style={total_result ? {display: 'block'} : {display: 'none'}} />
				<div>{total_result}</div>
			</>
		)


			//priceWithEventDiscount = Math.floor(price*(100-mainEventDiscount)/100)


	}

	const {
		register,
		handleSubmit,
    watch,
		setValue,
		formState: { errors },
		reset,
	} = useForm({
		mode: 'onChange',
	});

	useEffect(() => {

		//console.log(watch('promoCode'))

		try {
			async function fetchPromoData() {
				let code = watch('promoCode');
				const responsePromoCode = await apiCallsService.getPromoRequest({code: code});
				//console.log(responsePromoCode)
				if (responsePromoCode !== null) {
					setPromoCodeData(responsePromoCode)
					//setValue('promoCode', responsePromoCode.code)
					localStorage.setItem('usePromoCode', JSON.stringify({code:responsePromoCode.code}));
				}
				else {
					setPromoCodeData({discont: 0})
				}
			}
			fetchPromoData()
		}
		catch(e) {}


	}, [watch('promoCode')]);

	const handleChange = e => {
		setPromoCodeInput(prev => {
			return {
				...prev,
				[e.target.name]: e.target.value,
			};
		});
	};

	return (
		<WrapperMain>
			{/*
				<PromoInputCustom>
					<PromoTitle>PROMOCODE</PromoTitle>
					<PromoWaterText style={watch('promoCode')?.length>0 ? {opacity:'0'} : {opacity:'0.3'}}>ENTER NERV PROMOCODE</PromoWaterText>
				</PromoInputCustom>
				<WrapperInput>
					<MailInput
						type='text'
						name='promoCode'
						{...register('promoCode', {
							required: '',
						})}
					/>
				</WrapperInput>
				*/}


			<InputWrapper>
				<Input
					name='text'
					{...register('promoCode', {
						required: '',
					})}
				/>
				<InputPlaceHolder error={errors.email?.message}>
					PROMOCODE
				</InputPlaceHolder>
			</InputWrapper>

			<PromoInfo>
				<DescriptionDiscount cart={cart} mainEvent={mainEvent} promoCodeData={promoCodeData} />
			</PromoInfo>

			<ContentPrice>
				<ContentPriceItem>
					<div>SUBTOTAL</div>
					<div>SHIPPING</div>
					<div>TAX NOT INCLUDED</div>
					<div>PROMO</div>
					<div>TOTAL</div>
				</ContentPriceItem>
				<ContentPriceItem>
					<div><TotalResult cart={cart} mainEventDiscount={mainEvent.discont} promoCodeData={promoCodeData} dataReturn={'subtotal'}/></div>
					<div>
						{cart.subtotal < 200 ? 'DEPENDS ON COUNTRY' : 'FREE SHIPPING'}
					</div>
					<div>0.00</div>
					<div>{promoCodeData.maxUse > promoCodeData.alreadyUse ? parseInt(promoCodeData.discont) : 0 | 0}%</div>
					<div><TotalResult cart={cart} mainEventDiscount={mainEvent.discont} promoCodeData={promoCodeData} dataReturn={'total'}/></div>
				</ContentPriceItem>
			</ContentPrice>

		</WrapperMain>
	);
};
