import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useIsOverflow } from '../../hooks/IfOverflow';
import apiCallsService from '../../services/apiCalls.service';

const Wrapper = styled.div`
	position: relative;
	width: 35%;
	height: 100%;
	@media screen and (max-width: 1100px) {
		width: 100%;
		display: flex;
		flex-direction: column-reverse;
	}
`;

const WrapperTitle = styled.div`
	display: flex;
	align-items: flex-end;
	height: 3.8rem;
	width: 100%;
	border-bottom: 1px solid ${props => props.theme.colorBorder};
	padding-left: 0.7rem;
	padding-bottom: 0.7rem;
	& > h2 {
		font-weight: 400;
		font-size: 1.2rem;
		line-height: 1.8rem;
		color: ${props => props.theme.colorBlack};
	}
	@media screen and (max-width: 1100px) {
		display: none;
	}
`;

const WrapperTop = styled.div`
	position: relative;
	display: flex;
	align-items: flex-end;
	font-weight: 400;
	font-size: 1.2rem;
	line-height: 1.8rem;
	color: ${props => props.theme.colorBlack};
	height: 4.6rem;
	width: 100%;
	padding-right: 1.2rem;
	border-bottom: 1px solid ${props => props.theme.colorBorder};
	@media screen and (max-width: 1100px) {
		display: none;
	}
`;

const WrapperTopItem = styled.div`
	display: flex;
	align-items: center;
	width: calc(100% - 22% - 11rem);
	height: 100%;
	border-right: 1px solid ${props => props.theme.colorBorder};
	padding-top: 1rem;
	padding-right: 1rem;
	padding-bottom: 1rem;
	&:nth-child(2) {
		gap: 10rem;
		padding-left: 2.3rem;
	}

	&:first-child {
		width: 11rem;
		padding-left: 0.7rem;
		border-right: 1px solid ${props => props.theme.colorBorder};
	}
	&:last-child {
		align-items: center;
		justify-content: center;
		width: 22%;
		border-right: none;
	}
`;

const WrapperCover = styled.div`
	position: relative;
	width: 100%;
	height: calc(100% - 20.7rem);

	@media screen and (max-width: 1100px) {

	}

	&::after {
		position: absolute;
		right: 1.1rem;
		top: 0;
		content: '';
		width: 1px;
		height: 100%;
		background: ${props => props.theme.colorBorder};
		@media screen and (max-width: 1100px) {
			display: none;
		}
	}
`;

const WrapperBottom = styled.div`
	position: absolute;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	height: 12.4rem;
	border-top: 1px solid ${props => props.theme.colorBorder};
	border-right: 1px solid ${props => props.theme.colorBorder};
	width: calc(100% - 1.1rem);
	background: #cecece;
	@media screen and (max-width: 1100px) {
		position: static;
		//border-top: unset;
		//border-bottom: 1px solid ${props => props.theme.colorBorder};
		//border-right: unset;
		border:none !important;
		width: 100%;
		height: auto;
		padding: 2rem 4.2rem;
	}
`;

const WrapperMain = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	height: 100%;
	overflow-y: auto;
	width: calc(100% - 0.3rem);
	padding-right: ${props =>
		props.scroll === 'showScroll' ? '0.5rem' : '0.9rem'};

	&::-webkit-scrollbar {
		width: 0.4rem;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 12px;
		background-color: ${props => props.theme.colorBlack};
	}
	@media screen and (max-width: 1100px) {
		width: 100%;
		//border-top: 1px solid ${props => props.theme.colorBorder};
		padding-right: 0;
	}
`;

const Item = styled.div`
	display: flex;
	align-items: center;
	height: 11rem;
	width: 100%;
	border-bottom: 1px solid ${props => props.theme.colorBorder};
	&:last-child {
		border-bottom: ${props =>
			props.scroll === 'showScroll'
				? 'unset'
				: `1px solid ${props => props.theme.colorBorder}`};
	}
	@media screen and (max-width: 1100px) {
		border:none !important;
		height: 23rem;
	}
	@media screen and (max-width: 567px) {
		height: 17rem;
	}
`;

const ItemImage = styled.div`
	width: 11rem;
	height: 11rem;
	border-right: 1px solid ${props => props.theme.colorBorder};
	@media screen and (max-width: 1100px) {
		width: 26%;
		height: 100%;
		border: none !important;
		margin-left: 1.2rem;
	}
`;

const ItemImageCover = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	@media screen and (max-width: 1100px) {
		padding: 0rem;
	}
`;

const Image = styled.img`
	max-width: 100%;
	@media screen and (max-width: 1100px) {
		max-width: 80%;
	}
`;

const ItemInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: calc(100% - 22% - 11rem);
	height: 100%;
	border-right: 1px solid ${props => props.theme.colorBorder};
	padding: 1rem 2.9rem 1rem 2.3rem;

	& > p {
		font-weight: 300;
		font-size: ${props => props.theme.fontxsm};
		line-height: 1.4rem;
		color: ${props => props.theme.colorBlack};
		&:first-child {
			text-transform: uppercase;
		}
	}
	& > div {
		font-weight: 300;
		font-size: 1.2rem;
		line-height: 1.7rem;
		color: ${props => props.theme.colorBlack};
		margin-top: 0.9rem;
		text-transform: uppercase;
	}
	@media screen and (max-width: 1100px) {
		display:none !important;
		border-right: none;
		width: 60%;
		& > p {
			font-size: ${props => props.theme.fontmd};
			line-height: 2.2rem;
			&:first-child {
				font-size: 2.5rem;
				margin-bottom: 0.5rem;
			}
		}
		& > div {
			font-size: ${props => props.theme.fontmd};
			line-height: 2.2rem;
		}
	}
`;

const ItemPrice = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 22%;
	height: 100%;
	padding: 1rem;
	& > span {
		font-weight: 400;
		font-size: 1.2rem;
		line-height: 1.8rem;
		color: ${props => props.theme.colorBlack};
	}
	@media screen and (max-width: 1100px) {
		display: none;
	}
`;

const ItemPriceMob = styled.div`
	display: none;
	@media screen and (max-width: 1100px) {
		display: block;
		& > span {
			font-size: ${props => props.theme.fontmd};
			line-height: 2.2rem;
		}
	}
`;

const WrapperBottomItem = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	width: 50%;

	&:first-child {
		padding-left: 2.5rem;

	}

	&:nth-child(2) {
		padding-left: 2.3rem;
	}

	&:last-child {

		border-right: none;
		align-items: flex-end;
		padding-right: 2.5rem;
		& > div {
			&:nth-child(2) {
				font-weight: 600;
			}
		}
	}
	& > div {
		font-weight: 400;
		font-size: 1.2rem;
		line-height: 1.8rem;
		color: ${props => props.theme.colorBlack};
		&:last-child {
			font-weight: 600;
		}
	}
	@media screen and (max-width: 1100px) {
		& > div {
			font-size: ${props => props.theme.fontmd};
			line-height: 2.2rem;
			margin-bottom: 0.4rem;
			&:last-child {
				margin-bottom: 0;
			}
		}

		border-right: none;
		&:first-child {
			padding-right: 0;
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
			padding-left: 0;
		}
		&:nth-child(2) {
			padding-left: 0;
			padding-right: 0;
		}
	}
`;




const QuantityBox = styled.div`
	width: 85%;
	display: flex;
	justify-content: space-between;
`;

const TabletBottomRight = styled.div`
	display: none;
	flex-direction: column;
	justify-content: flex-start;
	border-left: 1px solid ${props => props.theme.colorBorder};
	padding: 1rem 2rem 1rem 4rem;
	@media screen and (max-width: 1100px) {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		border-left: 1px solid ${props => props.theme.colorBorder};
		padding: 1rem 2rem 1rem 0rem;
		border: none !important;
		margin-top:0.1rem;
		width:67.5%;
	}
`;

const TabletName = styled.div`
	font-size: 2.6rem;
	line-height: 3.3rem;
	color: ${props => props.theme.colorMain};
	@media screen and (max-width: 1100px) {
		font-size: ${props => props.theme.fontMob};
		line-height: 2.2rem;
		font-weight: 500;
	}
`;
const TabletDesc = styled.div`
	font-size: ${props => props.theme.fontmd};
	line-height: 2.2rem;
	color: ${props => props.theme.colorMain};
	@media screen and (max-width: 1100px) {
		display:none;
	}
	@media screen and (max-width: 567px) {
		font-size: 1.4rem;
		line-height: 1.8rem;
	}
`;

const TabletType = styled.div`
	display: flex;
	justify-content: space-between;
	font-size: ${props => props.theme.fontmd};
	line-height: 2.2rem;
	color: ${props => props.theme.colorMain};
	text-transform: uppercase;
	margin: 3.6rem 0 3.2rem;
	@media screen and (max-width: 567px) {
		margin: 1.2rem 0 0rem;
		font-size: ${props => props.theme.fontMob};
		line-height: 2.2rem;
		font-weight: 500;
	}
`;

const TabletQuantity = styled.div`
	display:flex;
	align-items: center;
	justify-content:space-between;
	font-size: ${props => props.theme.fontmd};
	line-height: 2.2rem;
	color: ${props => props.theme.colorMain};
	text-transform: uppercase;
	@media screen and (max-width: 1100px) {
		margin: 1.2rem 0 0rem;
		font-size: ${props => props.theme.fontMob};
		line-height: 2.2rem;
		font-weight: 500;
		width: 61%;
	}

`;

const TabletInStock = styled.div`
	font-size: ${props => props.theme.fontmd};
	line-height: 2.2rem;
	color: ${props => props.theme.colorMain};
	text-transform: uppercase;
	margin-bottom: 1rem;
	@media screen and (max-width: 1100px) {
		margin-bottom: 0.6rem;
	}
	@media screen and (max-width: 567px) {
		margin: 1.2rem 0 0rem;
		font-size: ${props => props.theme.fontMob};
		line-height: 2.2rem;
		font-weight: 500;
	}
`;

const TablePrice = styled.div`

	@media screen and (max-width: 1100px) {
		font-weight: 600;
		font-size: ${props => props.theme.fontMob};
		line-height: 2.2rem;
	}
`;

const GearPrice = styled.div`
	display:flex;
	justify-content: flex-end;

	font-size: 1.2rem;
	line-height: 1.8rem;
	font-family: Fira Code;
	font-weight: 600;
	text-align: right;
	@media screen and (max-width: 1100px) {
		font-weight: 600;
		font-size: ${props => props.theme.fontMob};
		line-height: 2.2rem;
	}
`;

export const Summary = () => {
	const cart = useSelector(state => state.cart);
	const [scroll, setScroll] = useState('');
	const ref = useRef();
	const [promoCodeData, setPromoCodeData] = useState({});
	const [mainEvent, setMainEvent] = useState({});

	useEffect(() => {

		try{
			async function fetchEventData() {
				const events_response = await apiCallsService.getEventsRequest();
				if (events_response.active)
					setMainEvent(events_response)

				console.log(events_response)
			}
			fetchEventData()
		}catch(e){}

		try {
			async function fetchPromoData() {
				let code = localStorage.getItem('promoCode');
				const responsePromoCode = await apiCallsService.getPromoRequest(JSON.parse(code));
				console.log(responsePromoCode)
				if (responsePromoCode !== null) {
					setPromoCodeData(responsePromoCode)
				}
			}
			fetchPromoData()
		}
		catch(e) {}

	}, []);

	useIsOverflow(ref, isOverflowFromCallback => {
		isOverflowFromCallback ? setScroll('showScroll') : setScroll('');
	});


	const TotalResult = ({cart = undefined, mainEventDiscount = undefined, promoCodeDiscount = undefined, dataReturn = 'total'}) => {
		let total_priceWithEventDiscount = 0;
		let total_priceWithPromoCode = 0;
		let total_priceWithProductDiscount = 0;
		let total_sum_price = 0;
		let best_discount = ''
		let promoDiscont = parseInt(promoCodeDiscount)

		let shipping = 0;

		let total_result = 0;

		if (cart) {
			cart.products.forEach((el , index)=>{
				if (el.priceWithDiscount!=='' & el.priceWithDiscount > 0) {
					total_priceWithProductDiscount+=(el.priceWithDiscount*el.quantity);
				}
				else {
					total_priceWithProductDiscount+=(el.price*el.quantity);
				}
				total_sum_price+=el.price*el.quantity;
			})
		}

		if (total_priceWithProductDiscount < total_sum_price)
			best_discount = 'ProductDiscount'

		if (mainEventDiscount != undefined) {
			total_priceWithEventDiscount = total_sum_price*(100-mainEventDiscount)/100;
			if (total_priceWithEventDiscount < total_sum_price & total_priceWithEventDiscount < total_priceWithProductDiscount)
				best_discount = 'EventDiscount'
		}
		if (promoCodeDiscount != undefined) {
			total_priceWithPromoCode = total_sum_price*(100-promoDiscont)/100;

			if (total_priceWithPromoCode < total_sum_price & total_priceWithPromoCode < total_priceWithProductDiscount ) {
				if (mainEventDiscount != undefined) {
					if (total_priceWithPromoCode < total_priceWithEventDiscount) {
						best_discount = 'promoCodeDiscount'
					}
				}
				else {
					best_discount = 'promoCodeDiscount'
				}
			}
		}

		if (best_discount === '')
			total_result = total_sum_price

		if (best_discount === 'ProductDiscount')
			total_result = total_priceWithProductDiscount

		if (best_discount === 'EventDiscount')
			total_result = total_priceWithEventDiscount

		if (best_discount === 'promoCodeDiscount')
			total_result = total_priceWithPromoCode

		if (dataReturn === 'total')	{
			if (cart.location.toLowerCase() !== 'ukraine' & cart.location.toLowerCase() !== '' & total_result < 200) {
				shipping = 35;
			}
			return (<div>${total_result+shipping}</div>)
		}
		if (dataReturn === 'subtotal')	{
			return (<div>${total_sum_price}</div>)
		}
		return (<div>${total_result}</div>)

	}

	function totalSumResult(cart = undefined, mainEventDiscount = undefined, promoCodeDiscount = undefined, dataReturn = 'total'){
		let total_priceWithEventDiscount = 0;
		let total_priceWithPromoCode = 0;
		let total_priceWithProductDiscount = 0;
		let total_sum_price = 0;
		let best_discount = ''
		let promoDiscont = parseInt(promoCodeDiscount)

		let shipping = 0;

		let total_result = 0;

		if (cart) {
			cart.products.forEach((el , index)=>{
				if (el.priceWithDiscount!=='' & el.priceWithDiscount > 0) {
					total_priceWithProductDiscount+=(el.priceWithDiscount*el.quantity);
				}
				else {
					total_priceWithProductDiscount+=(el.price*el.quantity);
				}
				total_sum_price+=el.price*el.quantity;
			})
		}

		if (total_priceWithProductDiscount < total_sum_price)
			best_discount = 'ProductDiscount'

		if (mainEventDiscount != undefined) {
			total_priceWithEventDiscount = total_sum_price*(100-mainEventDiscount)/100;
			if (total_priceWithEventDiscount < total_sum_price & total_priceWithEventDiscount < total_priceWithProductDiscount)
				best_discount = 'EventDiscount'
		}
		if (promoCodeDiscount != undefined) {
			total_priceWithPromoCode = total_sum_price*(100-promoDiscont)/100;

			if (total_priceWithPromoCode < total_sum_price & total_priceWithPromoCode < total_priceWithProductDiscount ) {
				if (mainEventDiscount != undefined) {
					if (total_priceWithPromoCode < total_priceWithEventDiscount) {
						best_discount = 'promoCodeDiscount'
					}
				}
				else {
					best_discount = 'promoCodeDiscount'
				}
			}
		}

		if (best_discount === '')
			total_result = total_sum_price

		if (best_discount === 'ProductDiscount')
			total_result = total_priceWithProductDiscount

		if (best_discount === 'EventDiscount')
			total_result = total_priceWithEventDiscount

		if (best_discount === 'promoCodeDiscount')
			total_result = total_priceWithPromoCode

		if (dataReturn === 'total')	{

			return (total_result)
		}
		if (dataReturn === 'subtotal')	{
			return (total_sum_price)
		}
		return (total_result)

	}


	const TotalPrice = ({price, priceWithDiscount = undefined, mainEventDiscount = undefined}) => {
		let priceWithEventDiscount = undefined;

		if (mainEventDiscount)
			priceWithEventDiscount = price*(100-mainEventDiscount)/100

		if (priceWithDiscount!=undefined & priceWithDiscount!== '' & priceWithDiscount!== 0 & mainEventDiscount!=undefined) {
			if (priceWithEventDiscount < priceWithDiscount) {
				return (
						<GearPrice>${priceWithEventDiscount} <div style={{textDecoration: 'line-through', opacity: '0.5', marginLeft:'1rem'}}>${price}</div></GearPrice>
				)
			}
			else {
				return (
						<GearPrice>${priceWithDiscount} <div style={{textDecoration: 'line-through', opacity: '0.5', marginLeft:'1rem'}}>${price}</div></GearPrice>
				)
			}
		}
		if (priceWithDiscount) {
			return (
					<GearPrice>${priceWithDiscount} <div style={{textDecoration: 'line-through', opacity: '0.5', marginLeft:'1rem'}}>${price}</div></GearPrice>
			)
		}
		if (mainEventDiscount) {
			return (
					<GearPrice>${priceWithEventDiscount} <div style={{textDecoration: 'line-through', opacity: '0.5', marginLeft:'1rem'}}>${price}</div></GearPrice>
			)
		}
		return (
				<GearPrice>${price}</GearPrice>
		)
	}

	return (
		<Wrapper>
			<WrapperBottom>
				<WrapperBottomItem>
					<div>SUBTOTAL</div>
					<div>SHIPPING</div>
					<div>TAX NOT INCLUDED</div>
					<div>PROMO</div>
					<div>TOTAL</div>
				</WrapperBottomItem>
				<WrapperBottomItem>
					<div><TotalResult cart={cart} mainEventDiscount={mainEvent.discont} promoCodeDiscount={promoCodeData.discont} dataReturn={'subtotal'}/></div>
					{totalSumResult(cart, mainEvent.discont, promoCodeData.discont, 'total') > 200 ? (
						<div>FREE SHIPPING</div>
					) : (
						<div>
							{cart.location === ''
								? 'DEPENDS ON COUNTRY'
								: cart.location.toLowerCase() === 'ukraine'
								? '$ 0.00'
								: '$ 35.00'}
						</div>
					)}
					<div>0.00</div>
					<div>{parseInt(promoCodeData.discont) | 0}%</div>
						<div><TotalResult cart={cart} mainEventDiscount={mainEvent.discont} promoCodeDiscount={promoCodeData.discont} dataReturn={'total'}/></div>
				</WrapperBottomItem>
			</WrapperBottom>
			<WrapperTitle>
				<h2>SUMMARY</h2>
			</WrapperTitle>
			<WrapperTop>
				<WrapperTopItem>PREVIEW</WrapperTopItem>
				<WrapperTopItem>
					<span>TXT</span>
					<span>INFO</span>
				</WrapperTopItem>
				<WrapperTopItem>PRICE</WrapperTopItem>
			</WrapperTop>
			<WrapperCover>
				<WrapperMain scroll={scroll} ref={ref}>
					{cart.products?.map((item, index) => (
						<Item key={`${index}_${item._id}`} scroll={scroll}>
							<ItemImage>
								<ItemImageCover>
									<Image src={item.imgMain} alt={item.name} />
								</ItemImageCover>
							</ItemImage>
							<ItemInfo>
								<p>{item.name}</p>
								<div>
									{item.model} / {item.description.color} /{' '}
									{item.description.sizeFull}
								</div>
								<QuantityBox>
									<div>{'QUANTITY'}</div>
									<div>{item.quantity}</div>
								</QuantityBox>
								<ItemPriceMob>
									<TotalPrice price={item.price} priceWithDiscount={item.priceWithDiscount}  mainEventDiscount={mainEvent.active ? mainEvent.discont : undefined} />
								</ItemPriceMob>
							</ItemInfo>
							<ItemPrice>
								<TotalPrice price={item.price} priceWithDiscount={item.priceWithDiscount}  mainEventDiscount={mainEvent.active ? mainEvent.discont : undefined} />
							</ItemPrice>

							<TabletBottomRight>
								<TabletName>{item.name}</TabletName>
								<TabletDesc>{item.desc}</TabletDesc>
								<TabletType>
									<div>{item.model} / {item.description.color} / {item.description.sizeFull}</div>
									<TablePrice><TotalPrice price={item.price} priceWithDiscount={item.priceWithDiscount}  mainEventDiscount={mainEvent.active ? mainEvent.discont : undefined} /></TablePrice>
								</TabletType>
								<TabletQuantity><div>QUANTITY:</div><div>{item.quantity}</div></TabletQuantity>
								<TabletInStock>
									ONLY {item.description.inStock} ITEM LEFT
								</TabletInStock>
							</TabletBottomRight>

						</Item>
					))}
				</WrapperMain>
			</WrapperCover>
		</Wrapper>
	);
};
