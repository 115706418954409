import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { addProduct } from '../../redux/cartRedux';
import { ZoomImage } from '../productComponent/ZoomImage';
import { Options } from './Options';
import { ProductCharacteristic } from './ProductCharacteristic';
import { ProductPhotos } from './ProductPhotos';
import apiCallsService from '../../services/apiCalls.service';
import { SizeChart } from '../../modals/SizeChart';
import { ModalProdAdd } from '../../modals/ModalProdAdd';

const MainWrapper = styled.div`
	padding: 2rem 0 1.5rem 2rem;
	width: calc(100% - 10rem);
	height: 100%;
	display: flex;
	align-items: top;
	gap: 0.6rem;
	overflow: visible;
	@media screen and (max-width: 1100px) {
		flex-direction: column;
		width: 100%;
		padding: 0;
		gap: 0;
	}
`;

const MainLeft = styled.div`
	width: 31.7%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	overflow-y: auto;
	padding-bottom: 0.3rem;
	transition: all 0.4s ease;



	&::-webkit-scrollbar {
		width: 0.4rem;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 12px;
		background-color: ${props => props.theme.colorBlack};
	}
	@media screen and (max-width: 1100px) {
		width: 100%;
		padding-bottom: 0;
	}
`;

const MainLeftTop = styled.div`
	position: relative;
	border-top-right-radius: 3rem;
	padding-top: 3.2rem;
	margin-right: 0.6rem;
	@media screen and (max-width: 1100px) {
		border-left: none;
		border-right: none;
		margin-right: 0;
	}
`;
const Angle = styled.div`
	position: absolute;
	right: 0;
	top: -1px;
	border-top-right-radius: 2.6rem;
	padding: 2.2rem 3.3rem 0.7rem 8rem;

	& > span {
		font-family: Fira Code;
		font-weight: 400;
		font-size: 1.2rem;
		line-height: 1.8rem;
		color: #000000;
		text-transform: uppercase;
		user-select: none;
	}
	@media screen and (max-width: 1100px) {
		top: 2rem;
		& > span {
			font-size: ${props => props.theme.fontMob};
			line-height: 2.2rem;
		}
	}
`;

const Model = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: 0 3.5rem 1.9rem;
	user-select: none;
	@media screen and (max-width: 1100px) {
		padding-bottom: 3rem;
	}
`;

const ModelBlock = styled.div`
	margin-top: 4.2rem;
	width: 80%;
	@media screen and (max-width: 1100px) {
		width: 70%;
		margin-top: 0.5rem;
	}
`;

const ModelBlockName = styled.h3`
	font-weight: 450;
	font-size: 3rem;
	line-height: 2.4rem;
	vertical-align: text-bottom;
	color: ${props => props.theme.colorBlack};
	text-transform: uppercase;
	@media screen and (max-width: 1100px) {
		font-size: 4.2rem;
		line-height: 4rem;
	}
`;

const ModelBlockType = styled.h4`
	margin-top: 1.4rem;
	font-weight: 450;
	font-size: ${props => props.theme.fontsm};
	line-height: 1.8rem;
	color: ${props => props.theme.colorBlack};
	margin-bottom: 1.2rem;
	text-transform: uppercase;
	@media screen and (max-width: 1100px) {
		font-size: ${props => props.theme.fontMob};
		line-height: 2.7rem;
	}
`;

const ModelBlockTypeName = styled.p`
	font-weight: 450;
	font-size: ${props => props.theme.fontsm};
	line-height: 1.8rem;
	color: ${props => props.theme.colorBlack};
	text-transform: uppercase;
	@media screen and (max-width: 1100px) {
		font-size: ${props => props.theme.fontMob};
		line-height: 2.1rem;
	}
`;

const ModelPrice = styled.div`
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 2rem;
	color: ${props => props.theme.colorBlack};
	@media screen and (max-width: 1100px) {
		font-size: 2.5rem;
		line-height: 3.3rem;
	}
`;

const Decoration = styled.div`
	position: relative;
	border: 1px solid ${props => props.theme.colorBlack};
	border-bottom-left-radius: 3rem;
	display: flex;
	align-items: center;
	margin-right: 0.6rem;
	&::after {
		position: absolute;
		right: 50%;
		top: 0;
		transform: translate(-50%);
		content: '';
		width: 1px;
		height: 100%;
		background: ${props => props.theme.colorBlack};
	}
	@media screen and (max-width: 1100px) {
		border-right: none;
		margin-right: 0;
	}
`;
const DecorationLeft = styled.div`
	width: 50%;
	height: 100%;
	display: flex;
	align-items: center;
	padding-left: 1.8rem;
	& > span {
		background: ${props => props.theme.colorBlack};
		width: 1.7rem;
		height: 1px;
		transform: translateX(1.2rem) rotate(45deg);
		&:last-child {
			transform: translateX(-0.5rem) rotate(-45deg);
		}
	}
`;
const DecorationRight = styled.div`
	display: flex;
	align-items: center;
	width: 50%;
	height: 100%;
	& > span {
		font-weight: 300;
		font-size: ${props => props.theme.fontxsm};
		line-height: 1.5rem;
		color: ${props => props.theme.colorBlack};
		margin: 0 3.4rem;
		padding: 0.85rem 0;
		text-transform: uppercase;
	}
`;
const Square = styled.div`
	position: relative;
	max-width: 4.3rem;
	width: 100%;
	height: 3.2rem;
	border-left: 1px solid ${props => props.theme.colorBlack};
	border-right: 1px solid ${props => props.theme.colorBlack};
	&::after {
		position: absolute;
		left: 50%;
		top: 50%;
		width: calc(100% + 1.1rem);
		height: 1px;
		content: '';
		background: ${props => props.theme.colorBlack};
		transform: translate(-50%, -50%) rotate(38deg);
	}
`;

const Button = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1.5rem 0 1.3rem;
	height: 4.6rem;
	width: calc(100% - 7.6rem);
	background: ${props => props.theme.colorMain};
	border: 1px solid transparent;
	margin: 1.5rem 3.5rem 1.5rem 3.5rem;
	text-align: center;
	cursor: pointer;
	transition: all 0.2s ease;
	filter: ${props => (props.inStock === 0 ? 'blur(1px)' : 'unset')};
	pointer-events: ${props => (props.inStock === 0 ? 'none' : 'auto')};

	& > span {
		font-weight: 450;
		font-size: ${props => props.theme.fontMain};
		line-height: 1.8rem;
		color: #adadad;
		text-align: center;
		text-transform: uppercase;
		transition: all 0.2s ease;
	}
	&:hover {
		background: transparent;
		border: 1px solid ${props => props.theme.colorMain};
		& > span {
			color: ${props => props.theme.colorMain};
		}
	}
	@media screen and (max-width: 1100px) {
		height: 6.2rem;
		width: 90%;
		margin: 0.9rem auto;
		margin-bottom: 3rem;
		margin-left: 3.8rem;
		& > span {
			font-size: ${props => props.theme.fontMob};
			line-height: 2.2rem;
		}
	}
	@media screen and (max-width: 567px) {
		height: 6rem;
	}
`;

const MainRight = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	width: 68.3%;
	margin-bottom: 0.6rem;
	@media screen and (max-width: 1100px) {
		width: 100%;
		height: 70vh;
		border: unset;
		margin-bottom: 0;
	}
	@media screen and (max-width: 567px) {
		height: calc(100vw*1.395);
	}
`;

const MainRightAside = styled.div`
	display: none;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
	height: 100%;
	border-right: 1px solid ${props => props.theme.colorBlack};
	padding: 3.1rem 1.1rem;
	& > span {
		font-weight: 300;
		font-size: 1.2rem;
		line-height: 1.8rem;
		color: ${props => props.theme.colorGray};
		transform: rotate(-180deg);
		writing-mode: vertical-lr;
	}
	@media screen and (max-width: 1100px) {
		display: none;
	}
`;

const InfoBox = styled.div`
	display:flex;
	flex-direction: column;

	user-select: none;
`;
const OpenContainerInfo = styled.div`
	display:flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: calc(100% - 7.8rem);
	margin: 0 3.6rem;
	font-family: Fira Code;
	font-size: 1.2rem;
	line-height: 1.8rem;
	font-weight: 600;
	text-align: left;
	user-select: none;
	overflow:hidden;
	transition: all 0.5s ease;


`;
const OpenContainerTitle = styled.div`
	display:flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	font-family: Fira Code;
	font-size: 1.2rem;
	line-height: 1.8rem;
	font-weight: 600;
	text-align: left;
	margin-bottom: 1rem;
	margin-top: 1.7rem;
	cursor: pointer;
	@media screen and (max-width: 1100px) {
		font-size: ${props => props.theme.fontMob};
		line-height: 2.2rem;
	}
`;

const OpenContainerInfoButton = styled.div`
	display:flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	font-family: Fira Code;
	font-size: 1.2rem;
	line-height: 1.8rem;
	font-weight: 400;
	text-align: left;
	@media screen and (max-width: 1100px) {
		font-size: ${props => props.theme.fontMob};
		line-height: 2.2rem;
	}
`;

const OpenContainerInfoTextBox = styled.div`
	width: 90%;
	transition: all 0.7s ease;
`;

const OpenContainerInfoText = styled.div`
	font-family: Fira Code;
	font-weight: 400;
	font-size: 1.1rem;
	line-height: 1.5rem;
	color: ${props => props.theme.colorBlack};
	margin-bottom: 0.1rem;
	@media screen and (max-width: 1100px) {
		font-size: ${props => props.theme.fontMob};
		line-height: 2.2rem;
	}
`;

const SpecWrapper = styled.ul`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;
	list-style: disc;
	@media screen and (max-width: 567px) {
		gap: 2rem;
		transform: translateX(0.3rem);
	}
`;

const SpecItem = styled.li`
	position: relative;
	list-style: disc;
	width: calc(50% - 2rem);
	font-weight: 400;
	font-size: 1.1rem;
	line-height: 1.5rem;
	color: ${props => props.theme.colorBlack};
	&:nth-of-type(3n) {
		margin-right: 0;
	}
	@media screen and (max-width: 1100px) {
		font-size: ${props => props.theme.fontMob};
		line-height: 2.2rem;
	}
	@media screen and (max-width: 567px) {
		width: calc(50% - 1rem);
		margin-right: unset;
	}
`;

const GearPrice = styled.div`
	display:flex;
	justify-content: flex-end;
	font-family: Fira Code;
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 2rem;
	color: ${props => props.theme.colorBlack};
	@media screen and (max-width: 1100px) {
		font-size: 2.5rem;
		line-height: 3.3rem;
	}
`;


const ChosenBorderContainer = styled.div`


	height: 4.4rem;
	width: 4.4rem;
	z-index: 5;
	user-select: none;
	display: flex;
	flex-direction: column;
	align-items : space-between;
	justify-content: space-between;
	transition: all 0.3s ease;
	@media screen and (max-width: 1100px) {
		position: absolute;
		left: 3.5rem;
		top: 77%;

		height: 6.7rem;
		width: 6.7rem;
	}

`;

const ChosenBorderTop = styled.div`
	width: 100%;
	min-height: 17%;
	max-height: 17%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	@media screen and (max-width: 1100px) {
		min-height: 20%;
		max-height: 20%;
	}
`;
const ChosenBorderBot = styled.div`
	width: 100%;
	min-height: 17%;
	max-height: 17%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	@media screen and (max-width: 1100px) {
		min-height: 20%;
		max-height: 20%;
	}
`;

const ChosenBorderTopLeft = styled.div`
	width: 17%;
	height: 100%;
	border-left: 1.5px solid #6D6D6D;
	border-top: 1.5px solid #6D6D6D;

	@media screen and (max-width: 1100px) {
			width: 20%;
	}
`;

const ChosenBorderTopRight = styled.div`
	width: 17%;
	height: 100%;
	border-right: 1.5px solid #6D6D6D;
	border-top: 1.5px solid #6D6D6D;

	@media screen and (max-width: 1100px) {
			width: 20%;
	}
`;

const ChosenBorderBotLeft = styled.div`
	width: 17%;
	height: 100%;
	border-left: 1.5px solid #6D6D6D;
	border-bottom: 1.5px solid #6D6D6D;

	@media screen and (max-width: 1100px) {
			width: 20%;
	}
`;

const ChosenBorderBotRight = styled.div`
	width: 17%;
	height: 100%;
	border-right: 1.5px solid #6D6D6D;
	border-bottom: 1.5px solid #6D6D6D;

	@media screen and (max-width: 1100px) {
			width: 20%;
	}
`;



const ChoseManPrev = styled.div`

	height: 4.4rem;
	width: 4.4rem;
	z-index: 3;
	cursor: pointer;
	fill: none;
	overflow: hidden;
	user-select: none;
	& > path {
		fill: #a0a0a0;
		transition: all 0.2s ease;
	}
	&:hover {
		& > path {
			fill: ${props => props.theme.colorMain};
		}
	}
	@media screen and (max-width: 1100px) {
		position: absolute;
		left: 3.5rem;
		top: 77%;

		height: 6.7rem;
		width: 6.7rem;
	}

`;


const ChoseWomanPrev = styled.div`


	height: 4.4rem;
	width: 4.4rem;
	z-index: 3;
	cursor: pointer;
	fill: none;
	user-select: none;

	transition: all 0.2s ease;

	overflow: hidden;
	& > path {
		fill: #a0a0a0;
		transition: all 0.2s ease;
	}
	&:hover {
		& > path {
			fill: ${props => props.theme.colorMain};
		}
	}

	@media screen and (max-width: 1100px) {
		position: absolute;
		left: 3.5rem;
		top: 88%;

		height: 6.7rem;
		width: 6.7rem;
	}
`;

const ImageAva = styled.img`

	width: 100%;
	height: 100%;
	object-position: center;
	object-fit: contain;
	background-repeat: no-repeat;
	background-size: 500%;
	object-size: 500%;
	user-select: none;
	transition: all 0.5s ease;
`;

const ChoseFirstPrev = styled.svg`


	height: 4.4rem;
	width: 4.4rem;
	z-index: 5;
	cursor: pointer;
	fill: none;
	user-select: none;
	pointer-events: ${props => (props.firstSlide ? 'none' : 'auto')};
	& > path {
		fill: #a0a0a0;
		transition: all 0.2s ease;
	}
	&:hover {
		& > path {
			fill: ${props => props.theme.colorMain};
		}
	}
	@media screen and (max-width: 1100px) {
		position: absolute;
		left: 3.5rem;
		top: 77%;

		height: 6.7rem;
		width: 6.7rem;
	}
`;

const ChoseSecondPrev = styled.svg`


	height: 4.4rem;
	width: 4.4rem;
	z-index: 5;
	cursor: pointer;
	fill: none;
	user-select: none;
	pointer-events: ${props => (props.firstSlide ? 'none' : 'auto')};
	& > path {
		fill: #a0a0a0;
		transition: all 0.2s ease;
	}
	&:hover {
		& > path {
			fill: ${props => props.theme.colorMain};
		}
	}
	@media screen and (max-width: 1100px) {
		position: absolute;
		left: 3.5rem;
		top: 88%;

		height: 6.7rem;
		width: 6.7rem;
	}
`;


const IconContainer = styled.div`
	display: flex;
	min-height: 4.4rem;
	width: 100%;
	padding: 0 3.5rem;
	gap: 0.35rem;

	@media screen and (max-width: 1100px) {
		display: none;
	}
`;


export const SingleProduct = ({ setShowmodalProduct, setShowmodalSize, setProdParam, showmodalsize, showmodalProduct }) => {
	const [showZoomImage, setShowZoomImage] = useState(false);
	const dispatch = useDispatch();
	const [curProd, setCurProd] = useState({});
	const [quantity, setQuantity] = useState(1);
	const [inStock, setInStock] = useState('');
	const [color, setColor] = useState('');
	const [price, setPrice] = useState('');
	const [size, setSize] = useState({});
	const location = useLocation();
	const productName = location.pathname.split('/')[2].replace(/-/gi, ' ');
	const cart = useSelector(state => state.cart);
	const data = useSelector(state => state.data);

	const [tehInfoOpen, setTehInfoOpen] = useState(true);
	const [specInfoOpen, setSpecInfoOpen] = useState(false);
	const [careInfoOpen, setCareInfoOpen] = useState(false);

	const [chooseMan, setChooseMan] = useState(true);
	const [needSlide, setNeedSlide] = useState(false);
	const [borderLeft, setBorderLeft] = useState("67.9%");

	const [mainEvent, setMainEvent] = useState({});

	const closeText = '[CLOSE]'
	const openText = '+'
	//console.log(inStock);

	useEffect(() => {
		const result = data?.find(item => item.name.toLowerCase() === productName);

		setCurProd(result);
		result?.onlySizes
			? setColor(result?.options[0]?.info[0].name)
			: setColor(result?.options[0]?.info.name);
		setPrice(result?.price);

		let currentModelTypeInfo;

		if (result.onlySizes) {
			currentModelTypeInfo = result?.options[0]?.modelTypeInfo;
		}

		if (!result.onlySizes) {
			result?.options[0].hasOwnProperty('sizes')
				? (currentModelTypeInfo = result?.options[0].sizes[0]?.modelTypeInfo)
				: (currentModelTypeInfo = result?.options[0].info?.modelTypeInfo);
		}
		setSize({
			sizeShort: result?.options[0]?.sizeShort,
			sizeFull: result?.options[0]?.sizeFull,
			modelTypeInfo: currentModelTypeInfo,
		});

		try{
			async function fetchEventData() {
				const events_response = await apiCallsService.getEventsRequest();
				setMainEvent(events_response)

			}
			fetchEventData()
		}catch(e){}

	}, [data, productName]);

	const handleClickBtn = id => {
		setShowmodalSize(false);

		const selectedItemInCart = cart.products.find(
			item =>
				item._id === id &&
				item.specificId === `${id}/${color}/${size.sizeShort}`
		);

		if (!selectedItemInCart) {
			setShowmodalProduct(true);

			setProdParam({size:size, color:color, quantity: quantity})

			dispatch(
				addProduct({
					...curProd,
					quantity,
					description: { ...size, color, inStock },
					specificId: `${id}/${color}/${size.sizeShort}`,
				})
			);
		}
	};


	const TotalPrice = ({price, priceWithDiscount = undefined, mainEventDiscount = undefined}) => {
		let priceWithEventDiscount = undefined;

		if (mainEventDiscount)
			priceWithEventDiscount = price*(100-mainEventDiscount)/100

		if (priceWithDiscount!=undefined & priceWithDiscount!== '' & priceWithDiscount!== 0 & mainEventDiscount!=undefined) {
			if (priceWithEventDiscount < priceWithDiscount) {
				return (
						<GearPrice>${priceWithEventDiscount} <div style={{textDecoration: 'line-through', opacity: '0.5', marginLeft:'1rem'}}>${price}</div></GearPrice>
				)
			}
			else {
				return (
						<GearPrice>${priceWithDiscount} <div style={{textDecoration: 'line-through', opacity: '0.5', marginLeft:'1rem'}}>${price}</div></GearPrice>
				)
			}
		}
		if (priceWithDiscount) {
			return (
					<GearPrice>${priceWithDiscount} <div style={{textDecoration: 'line-through', opacity: '0.5', marginLeft:'1rem'}}>${price}</div></GearPrice>
			)
		}
		if (mainEventDiscount) {
			return (
					<GearPrice>${priceWithEventDiscount} <div style={{textDecoration: 'line-through', opacity: '0.5', marginLeft:'1rem'}}>${price}</div></GearPrice>
			)
		}
		return (
				<GearPrice>${price}</GearPrice>
		)
	}




	const ChoseButtonMan = () => {

		const handleClickNext = () => {
			setBorderLeft("67.9%")
			setChooseMan(true);
			setNeedSlide(true);
		};

		return (
			<ChoseManPrev
			viewBox='0 0 9 16'
			onClick={() => handleClickNext()}
			>
			<ImageAva src={curProd.imgSliderIconMan?.replace('http:', 'https:')} alt='Nerv' style={chooseMan ? {opacity: "1"} : {opacity: "0.3"}} />
			</ChoseManPrev>
		);
	};

	const ChoseButtonWoman = () => {


		const handleClickPrev = () => {
			setBorderLeft("70.45%")
			setChooseMan(false);
			setNeedSlide(true);
		};

		return (
			<ChoseWomanPrev
				viewBox='0 0 9 16'
				onClick={() => handleClickPrev()}
			>
				<ImageAva src={curProd.imgSliderIconWoman?.replace('http:', 'https:')} alt='Nerv' style={chooseMan ? {opacity: "0.3"} : {opacity: "1"}} />
			</ChoseWomanPrev>
		);
	};

	const ChoseFirst = () => {
		const handleClickPrev = () => {
			setBorderLeft("67.9%")
			setChooseMan(true);
			setNeedSlide(true);
		};

		return (
			<ChoseFirstPrev
				viewBox='0 0 9 16'
				onClick={() => handleClickPrev()}
			>

			</ChoseFirstPrev>
		);
	};

	const ChoseSecond = () => {
		const handleClickPrev = () => {
			setBorderLeft("70.45%")
			setChooseMan(false);
			setNeedSlide(true);
		};

		return (
			<ChoseSecondPrev
				viewBox='0 0 9 16'
				onClick={() => handleClickPrev()}
			>
			</ChoseSecondPrev>
		);
	};


	const useViewport = () => {
		const [width, setWidth] = useState(window.innerWidth);

		useEffect(() => {
			const handleWindowResize = () => {
				setWidth(window.innerWidth);
			};

			window.addEventListener('resize', handleWindowResize);
			return () => window.removeEventListener('resize', handleWindowResize);
		}, []);

		return { width };
	};

	const { width } = useViewport();


	return (
		<>
			<ZoomImage
				curProd={curProd?.imgSliderZoom}
				showZoomImage={showZoomImage}
				setShowZoomImage={setShowZoomImage}
			/>

			<SizeChart
				key='modalsize'
				setShowmodalSize={setShowmodalSize}
				showmodalsize={showmodalsize}
				width={width}
			/>
			{showmodalProduct && (
				<ModalProdAdd
					key='modalProd'
					setShowmodalProduct={setShowmodalProduct}
					showmodalProduct={showmodalProduct}
				/>
			)}

			<MainWrapper style={(showmodalsize || showmodalProduct) & width<=1100 ? {height:'75vh'} : {} }>

				<MainRight>
					<MainRightAside>
						<span>CHARACTER ARAMETERS</span>
						<span>VISUAL ASSISTANT</span>
						<span>IMAGE QUANTITY</span>
					</MainRightAside>
					<ProductPhotos
						curProd={curProd}
						setShowZoomImage={setShowZoomImage}
						modelTypeInfo={size.modelTypeInfo}
						chooseMan={chooseMan}
						setChooseMan={setChooseMan}
						needSlide={needSlide}
						setNeedSlide={setNeedSlide}
						width={width}
					/>
				</MainRight>

				<MainLeft style={showmodalsize || showmodalProduct ? {height: '70%'} : {height: '100%'}}>
					<MainLeftTop>
						<Angle>
							<span>{curProd?.rarity}</span>
						</Angle>
						<Model>
							<ModelBlock>
								<ModelBlockName>{curProd?.name}</ModelBlockName>
								<ModelBlockType>MODEL TYPE</ModelBlockType>
								<ModelBlockTypeName>[ {curProd?.model} ]</ModelBlockTypeName>
							</ModelBlock>
							<TotalPrice price={curProd.price} priceWithDiscount={curProd.priceWithDiscount}  mainEventDiscount={mainEvent.active ? mainEvent.discont : undefined} />
						</Model>

					</MainLeftTop>


					{curProd?.imgSliderAppearance && curProd?.imgSliderAppearanceWoman ? (
						<>
							{/*<ChosenBorderContainer style={{bottom: borderBottom}}>*/}


							<IconContainer>
								<ChoseButtonMan />
								<ChoseButtonWoman />
							</IconContainer>

							<IconContainer style={{marginTop:"-4.4rem"}}>
								<ChoseFirst />
								<ChoseSecond />
							</IconContainer>

							<IconContainer style={{marginTop:"-4.4rem"}}>
								<ChosenBorderContainer  style={chooseMan ? {marginLeft: "0rem"} : {marginLeft: "4.7rem"}} >
									<ChosenBorderTop>
										<ChosenBorderTopLeft/>
										<ChosenBorderTopRight/>
									</ChosenBorderTop>
									<ChosenBorderBot>
										<ChosenBorderBotLeft/>
										<ChosenBorderBotRight/>
									</ChosenBorderBot>
								</ChosenBorderContainer>
							</IconContainer>
						</>
					) : null}




					<Options
						setShowmodal={setShowmodalProduct}
						setShowmodalSize={setShowmodalSize}
						curProd={curProd}
						setCurProd={setCurProd}
						setColor={setColor}
						setSize={setSize}
						size={size}
						color={color}
						setInStock={setInStock}
						inStock={inStock}
						setQuantity={setQuantity}
						quantity={quantity}
					/>

					<Button
						inStock={inStock}
						onClick={() => handleClickBtn(curProd?._id)}
					>
						<span>BUY NOW</span>
					</Button>

					<InfoBox>
					<OpenContainerInfo style={tehInfoOpen ? {maxHeight: '100%'} : {maxHeight: '3.6rem'}}>
						<OpenContainerTitle onClick={()=> setTehInfoOpen(!tehInfoOpen)}>
							<div style={tehInfoOpen ? {opacity: '1'} : {opacity: '0.3'}}>
								{'01 TECHNICAL INFORMATION'}
							</div>
							<OpenContainerInfoButton>
								{!tehInfoOpen && (<div style={{fontWeight: 800, fontSize: '1.8rem'}}>{openText}</div>)}
								{tehInfoOpen && (<div>{closeText}</div>)}
							</OpenContainerInfoButton>
						</OpenContainerTitle>
						<OpenContainerInfoTextBox style={tehInfoOpen ? {opacity: '1'} : {opacity: '0.3'}}>
							{curProd?.technicalInformation?.split('\n').map((item, index) => (
								<OpenContainerInfoText key={'technical_information_key_'+ index}>{item}</OpenContainerInfoText>
							))}
						</OpenContainerInfoTextBox>
					</OpenContainerInfo>


					<OpenContainerInfo style={specInfoOpen ? {maxHeight: '100%'} : {maxHeight: '3.6rem'}}>
						<OpenContainerTitle onClick={()=> setSpecInfoOpen(!specInfoOpen)}>
							<div style={specInfoOpen ? {opacity: '1'} : {opacity: '0.3'}}>
								{'02 SPECIFICATION'}
							</div>
							<OpenContainerInfoButton>
								{!specInfoOpen && (<div style={{fontWeight: 800, fontSize: '1.8rem'}}>{openText}</div>)}
								{specInfoOpen && (<div>{closeText}</div>)}
							</OpenContainerInfoButton>
						</OpenContainerTitle>
						<OpenContainerInfoTextBox style={specInfoOpen ? {opacity: '1'} : {opacity: '0.3'}}>
							{curProd?.specificationIndividual ? (
								<SpecWrapper>
									{curProd?.specificationIndividual
										?.split('\n')
										.map((item, index) => (
											<SpecItem key={item + index}>{item}</SpecItem>
										))}
								</SpecWrapper>
							) : (
								<>
									{curProd?.specification?.split('\n').map((item, index) => (
										<OpenContainerInfoText key={item + index}>{item}</OpenContainerInfoText>
									))}
								</>
							)}
						</OpenContainerInfoTextBox>
					</OpenContainerInfo>

					<OpenContainerInfo style={careInfoOpen ? {maxHeight: '100%'} : {maxHeight: '3.6rem'}}>
						<OpenContainerTitle onClick={()=> setCareInfoOpen(!careInfoOpen)}>
							<div style={careInfoOpen ? {opacity: '1'} : {opacity: '0.3'}}>
								{'03 CARE SPECIFICATION'}
							</div>
							<OpenContainerInfoButton>
								{!careInfoOpen && (<div style={{fontWeight: 800, fontSize: '1.8rem'}}>{openText}</div>)}
								{careInfoOpen && (<div>{closeText}</div>)}
							</OpenContainerInfoButton>
						</OpenContainerTitle>
						<OpenContainerInfoTextBox style={careInfoOpen ? {opacity: '1'} : {opacity: '0.3'}}>
							{curProd?.careSpecification?.split('\n').map((item, index) => (
								<OpenContainerInfoText key={'care_specification_key_' + index}>{item}</OpenContainerInfoText>
							))}
						</OpenContainerInfoTextBox>
					</OpenContainerInfo>

					</InfoBox>

				</MainLeft>

			</MainWrapper>
		</>
	);
};
