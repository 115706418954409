import { motion } from 'framer-motion';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled(motion.div)`
	position: fixed;
	right: 6%;
	top: 12.4rem;
	z-index: 9;
	width: 100%;
	max-width: 26.7%;
	height:70.5vh;
	background: #CECECE;
	transition: all 0.4s ease-out;
	@media screen and (max-width: 1100px) {
		position: absolute;
		top: 1.2rem;
		right: 0%;
		max-width: 100%;
		height:100vh;
	}
`;

const Info = styled.div`
	display: flex;
	align-items: center;
	gap: 3.9rem;
	font-family: Fira Code;
	font-size: ${props => props.theme.fontMain};
	font-weight: 600;
	line-height: 1.8rem;
	color: #191919;
	padding: 1rem 0 0.8rem 1rem;

	& > span {
		font-weight: 600;
		font-size: ${props => props.theme.fontsm};
		line-height: 2.5rem;
		color: #191919;
	}
	@media screen and (max-width: 1100px) {
		padding: 2rem 0 1.7rem 3.2rem;
		& > span {
			font-size: 2.1rem;
		}
		@media screen and (max-width: 567px) {
			& > span {
				font-size: 1.9rem;
			}
		}
	}
`;

const WrapperTop = styled.div`
	margin-top:1.2rem;
	position: relative;
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	& > span {
		text-transform: uppercase;
		font-weight: 300;
		font-size: ${props => props.theme.fontsm};
		line-height: 1.8rem;
		color: #282828;
		margin-bottom: 0.3rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
	@media screen and (max-width: 1100px) {
		& > span {
			font-size: 2.1rem;
			margin-bottom: 1rem;
		}
	}
	@media screen and (max-width: 567px) {
		& > span {
			font-size: 1.9rem;
		}
	}
`;

const Close = styled.div`
	position: absolute;
	right: 4.8rem;
	top: 0.7rem;
	width: 3rem;
	height: 3rem;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-family: Fira Code;
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 1.8rem;
	color: #000000;

	& > span {
		width: 100%;
		height: 2px;
		background: ${props => props.theme.colorGray};
		transform: translateX(0.8rem) rotate(-45deg);
		transition: all 0.2s ease;
		&:last-child {
			transform: translateX(-0.7rem) rotate(45deg);
		}
	}
	&:hover {
		& > span {
			background: #fff;
		}
	}
	@media screen and (max-width: 1100px) {
		width: 4rem;
		height: 4rem;
		top: 1.2rem;
		font-size: 1.7rem;
		line-height: 2.2rem;
		right: 6.2rem;
		& > span {
			transform: translateX(1.2rem) rotate(-45deg);
		}
	}

`;

const WrapperMain = styled.div`
	background: #CECECE;
	width: 100%;
	height: 100%;
`;

const WrapperBlock = styled.div`
	max-width: 58.5rem;
	width: 100%;
	height: 100%;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: space-between;
	@media screen and (max-width: 1100px) {
		padding: 2.2rem 0 3.2rem;
		max-width: 90%;
		justify-content: flex-start;
	}
	@media screen and (max-width: 567px) {
		& > span {
			padding: 2rem 0 3rem;
		}
	}
`;



const SizeImage = styled.div`
	margin: auto;
	max-width: 37rem;
	height: 39rem;
	width: 100%;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	@media screen and (max-width: 1100px) {
		margin: auto;
		max-width: 43rem;
		height: 43rem;
		margin-top: 2.9rem;
		margin-bottom: 4.1rem;

	}

`;

const Parameters = styled.div`
	margin-bottom: 3rem;

	& > span {
		font-weight: 300;
		font-size: ${props => props.theme.fontsm};
		line-height: 2.3rem;
		color: #282828;
	}
	@media screen and (max-width: 1100px) {
		& > span {
			font-size: 2.3rem;
		}
	}
	@media screen and (max-width: 567px) {
		margin-bottom: 2.5rem;
		& > span {
			font-size: 2rem;
		}
	}
`;


const Container = styled.div`
	width:100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
`;
const BLock = styled.div`
	width:100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 4rem;
`;

const DopText = styled.div`
	margin-top: 2.3rem;
	width:100%;
	display: flex;
	align-items: flex-end;
	justify-content:right;
	font-family: Fira Code;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.6rem;

	@media screen and (max-width: 1100px) {
		margin-top: 4.8rem;
		font-size: 1.9rem;
	}

`;

export const SizeChart = ({ showmodalsize, setShowmodalSize, width }) => {
	const [curProdSizeChart, setCurProdSizeChart] = useState([]);
	const [imgSizeChart, setImgSizeChart] = useState('');
	const location = useLocation();
	const pathName = location.pathname.split('/')[2];

	const data = useSelector(state => state.data);

	useEffect(() => {
		const curProd = data.find(
			item => item.name.replace(/ /gi, '-').toLowerCase() === pathName
		);
		setCurProdSizeChart(curProd.sizeChart);
		console.log(curProd.imgSizeChart)
		setImgSizeChart(curProd.imgSizeChart)
	}, [data, pathName]);

	useEffect(() => {
		const element = document.getElementById("MainWrapId");

		if (showmodalsize) {
			element.style.display = "block";
			element.style.right = "-120%";

			setTimeout(() => {
				if (width<=1100) {
					element.style.right = "0%";
				}
				else {
					element.style.right = "6%";
				}
			}, "1");

		}
		if (!showmodalsize) {
			element.style.right = "-120%";
			setTimeout(() => {
				element.style.display = "none";
			}, "400");
		}
	}, [showmodalsize]);




	return (
		<Wrapper   id={'MainWrapId'}	>
			<WrapperTop>
				<Info>
					<span>[ PARAMETERS ]</span> <span></span>
				</Info>
				<Close onClick={() => setShowmodalSize(false)}>
					{'[CLOSE]'}
				</Close>
			</WrapperTop>
			<WrapperMain>
				<WrapperBlock>
					<SizeImage style={{backgroundImage: `url(${imgSizeChart})`}} />
					<Container>
						<BLock>
							{curProdSizeChart.map((item, index) => (
								<Item key={index + item[0]}>
									{item.map((size, index) => (
										<span key={size[0] + index}>{size}</span>
									))}
								</Item>
							))}
						</BLock>
						<DopText>{'*ALL MEASUREMENTS IN CENTIMETERS'}</DopText>
					</Container>
				</WrapperBlock>
			</WrapperMain>
		</Wrapper>
	);
};
