import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/mousewheel';
import { Mousewheel } from 'swiper';
import styled from 'styled-components';

const Image = styled.img`
	width: 100%;
	height: 100%;
	object-position: center;
	object-fit: contain;
	user-select: none;

	@media screen and (max-width: 1100px) {
		object-fit: cover;
	}
`;

const ImageAva = styled.img`

	width: 100%;
	height: 100%;
	object-position: center;
	object-fit: contain;
	background-repeat: no-repeat;
	background-size: 500%;
	object-size: 500%;
	user-select: none;
	transition: all 0.5s ease;
`;

const ArrowPrev = styled.svg`
	position: absolute;
	left: 2.5rem;
	top: 50%;
	transform: translateY(-50%);
	z-index: 2;
	cursor: pointer;
	width: ${props => props.arrowWidth};
	height: ${props => props.arrowHeight};
	fill: none;
	pointer-events: ${props => (props.firstSlide ? 'none' : 'auto')};
	& > path {
		fill: #a0a0a0;
		transition: all 0.2s ease;
	}
	&:hover {
		& > path {
			fill: ${props => props.theme.colorMain};
		}
	}
`;

const ArrowNext = styled.svg`
	position: absolute;
	right: 2.5rem;
	top: 50%;
	transform: translateY(-50%);
	z-index: 2;
	cursor: pointer;
	width: ${props => props.arrowWidth};
	height: ${props => props.arrowHeight};
	fill: none;
	pointer-events: ${props => (props.lastSlide ? 'none' : 'auto')};
	& > path {
		fill: #a0a0a0;
		transition: all 0.2s ease;
	}
	&:hover {
		& > path {
			fill: ${props => props.theme.colorMain};
		}
	}
`;

const ChoseManPrev = styled.div`
	display: none;
	position: fixed;
	left: calc(67.9%);
	height: 4.4rem;
	width: 4.4rem;
	top: 30.6rem;
	z-index: 3;
	cursor: pointer;
	fill: none;
	overflow: hidden;
	user-select: none;
	pointer-events: ${props => (props.firstSlide ? 'none' : 'auto')};
	& > path {
		fill: #a0a0a0;
		transition: all 0.2s ease;
	}
	&:hover {
		& > path {
			fill: ${props => props.theme.colorMain};
		}
	}
	@media screen and (max-width: 1100px) {
		display: block;
		position: absolute;
		left: 3.5rem;
		top: 77%;

		height: 6.7rem;
		width: 6.7rem;
	}

`;
const ChoseWomanPrev = styled.div`
	display: none;
	position: fixed;
	left: calc(70.45%);
	height: 4.4rem;
	width: 4.4rem;
	top: 30.6rem;
	z-index: 3;
	cursor: pointer;
	fill: none;
	user-select: none;

	transition: all 0.2s ease;

	overflow: hidden;
	pointer-events: ${props => (props.firstSlide ? 'none' : 'auto')};
	& > path {
		fill: #a0a0a0;
		transition: all 0.2s ease;
	}
	&:hover {
		& > path {
			fill: ${props => props.theme.colorMain};
		}
	}

	@media screen and (max-width: 1100px) {
		display: block;
		position: absolute;
		left: 3.5rem;
		top: 88%;

		height: 6.7rem;
		width: 6.7rem;
	}
`;


const ChoseSecondPrev = styled.div`
	display: none;
	position: fixed;
	left: calc(70.45%);
	height: 4.4rem;
	width: 4.4rem;
	top: 30.6rem;
	z-index: 5;
	cursor: pointer;
	fill: none;
	user-select: none;
	pointer-events: ${props => (props.firstSlide ? 'none' : 'auto')};
	& > path {
		fill: #a0a0a0;
		transition: all 0.2s ease;
	}
	&:hover {
		& > path {
			fill: ${props => props.theme.colorMain};
		}
	}
	@media screen and (max-width: 1100px) {
		display: block;
		position: absolute;
		left: 3.5rem;
		top: 88%;

		height: 6.7rem;
		width: 6.7rem;
	}
`;
const ChoseFirstPrev = styled.div`
	display: none;
	position: fixed;
	left: calc(67.9%);
	height: 4.4rem;
	width: 4.4rem;
	top: 30.6rem;

	z-index: 5;
	cursor: pointer;
	fill: none;
	user-select: none;
	pointer-events: ${props => (props.firstSlide ? 'none' : 'auto')};
	& > path {
		fill: #a0a0a0;
		transition: all 0.2s ease;
	}
	&:hover {
		& > path {
			fill: ${props => props.theme.colorMain};
		}
	}
	@media screen and (max-width: 1100px) {
		display: block;
		position: absolute;
		left: 3.5rem;
		top: 77%;

		height: 6.7rem;
		width: 6.7rem;
	}
`;

const ChosenBorderContainer = styled.div`
	display:none;
	position: fixed;
	left: calc(67.9%);
	height: 4.4rem;
	width: 4.4rem;
	top: 30.6rem;
	z-index: 5;
	user-select: none;
	display: flex;
	flex-direction: column;
	align-items : space-between;
	justify-content: space-between;
	transition: all 0.3s ease;
	@media screen and (max-width: 1100px) {
		display: flex;
		position: absolute;
		left: 3.5rem;
		top: 77%;

		min-height: 6.7rem;
		width: 6.7rem;
	}

`;

const ChosenBorderTop = styled.div`
	width: 100%;
	min-height: 17%;
	max-height: 17%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	@media screen and (max-width: 1100px) {
		min-height: 20%;
		max-height: 20%;
	}
`;
const ChosenBorderBot = styled.div`
	width: 100%;
	min-height: 17%;
	max-height: 17%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	@media screen and (max-width: 1100px) {
		min-height: 20%;
		max-height: 20%;
	}
`;

const ChosenBorderTopLeft = styled.div`
	width: 17%;
	height: 100%;
	border-left: 1.5px solid #6D6D6D;
	border-top: 1.5px solid #6D6D6D;

	@media screen and (max-width: 1100px) {
			width: 20%;
	}
`;

const ChosenBorderTopRight = styled.div`
	width: 17%;
	height: 100%;
	border-right: 1.5px solid #6D6D6D;
	border-top: 1.5px solid #6D6D6D;

	@media screen and (max-width: 1100px) {
			width: 20%;
	}
`;

const ChosenBorderBotLeft = styled.div`
	width: 17%;
	height: 100%;
	border-left: 1.5px solid #6D6D6D;
	border-bottom: 1.5px solid #6D6D6D;

	@media screen and (max-width: 1100px) {
			width: 20%;
	}
`;

const ChosenBorderBotRight = styled.div`
	width: 17%;
	height: 100%;
	border-right: 1.5px solid #6D6D6D;
	border-bottom: 1.5px solid #6D6D6D;

	@media screen and (max-width: 1100px) {
			width: 20%;
	}
`;

const IconContainer = styled.div`
	display: none;


	@media screen and (max-width: 1100px) {
		display: flex;
	}
`;

export const SliderProduct = ({
	firstSlide,
	lastSlide,
	setLastSlide,
	setFirstSlide,
	setCurrentSlide,
	curProd,
	arrowWidth,
	arrowHeight,
	product,
	chooseMan,
	setChooseMan,
	needSlide,
	setNeedSlide,
	width,
}) => {

	const [borderBottom, setBorderBottom] = useState("4%");

	const [lastChose, setLastChose] = useState(true);


	const [productSlider, setProductSlider] = useState([]);

	const [borderLeft, setBorderLeft] = useState("67.9%");

	const [activeSlideNumber, setActiveSlideNumber] = useState(0);

	useEffect(() => {

		if (product) {
			if (product.length !== 0) {


				if (product.imgSlider != undefined & product.imgSliderWoman != undefined) {
						setProductSlider([...product.imgSlider, ...product.imgSliderWoman])
				}
				else {
					if (product.imgSlider != undefined) {
						setProductSlider([...product.imgSlider])
					}
					if (product.imgSliderWoman != undefined) {
						setProductSlider([...product.imgSliderWoman])
					}
				}
			}
		}

	}, [product]);







	const ChoseFirst = () => {
		const swiper = useSwiper();
		const handleClickPrev = () => {
			let change = activeSlideNumber
			productSlider.forEach(()=> {
				if (change>0) {
					change-=1
				}
				swiper.slidePrev();
			})
			setActiveSlideNumber(change)
			setBorderLeft("67.9%")
			setChooseMan(true);
		};

		useEffect(() => {
			if (lastChose !== chooseMan & needSlide) {
				setNeedSlide(false)
				setLastChose(chooseMan)

				if (chooseMan) {
					let change = activeSlideNumber
					productSlider.forEach(()=> {
						if (change>0) {
							change-=1
						}
						swiper.slidePrev();
					})
					setActiveSlideNumber(change)
				} else {

					let change = activeSlideNumber
					productSlider.forEach(()=> {
						if (change>0) {
							change-=1
						}
						swiper.slidePrev();
					})

					product.imgSlider.forEach(()=> {
						if (change < productSlider.length) {
								change+=1
						}
						swiper.slideNext()
					})
					setActiveSlideNumber(change)
				}
			}


		}, [chooseMan]);

		return (
			<ChoseFirstPrev
				viewBox='0 0 9 16'
				onClick={() => handleClickPrev()}
				firstSlide={firstSlide}
				arrowWidth={arrowWidth}
				arrowHeight={arrowHeight}
			>

			</ChoseFirstPrev>
		);
	};

	const ChoseSecond = () => {
		const swiper = useSwiper();
		const handleClickPrev = () => {
			let change = activeSlideNumber
			productSlider.forEach(()=> {
				if (change>0) {
					change-=1
				}
				swiper.slidePrev();
			})

			product.imgSlider.forEach(()=> {
				if (change < productSlider.length) {
						change+=1
				}
				swiper.slideNext()
			})
			setActiveSlideNumber(change)

			setBorderLeft("70.45%")
			setChooseMan(false);
		};

		return (
			<ChoseSecondPrev
				viewBox='0 0 9 16'
				onClick={() => handleClickPrev()}
				lastSlide={lastSlide}
				arrowWidth={arrowWidth}
				arrowHeight={arrowHeight}
			>

			</ChoseSecondPrev>
		);
	};

	const ChoseButtonWoman = () => {
		const swiper = useSwiper();
		const handleClickPrev = () => {
			let change = activeSlideNumber
			productSlider.forEach(()=> {
				if (change>0) {
					change-=1
				}
				swiper.slidePrev();
			})

			product.imgSlider.forEach(()=> {
				if (change < productSlider.length) {
						change+=1
				}
				swiper.slideNext()
			})
			setActiveSlideNumber(change)
			setBorderLeft("70.45%")
			setChooseMan(false);
		};

		return (
			<ChoseWomanPrev
				viewBox='0 0 9 16'
				onClick={() => handleClickPrev()}
				firstSlide={firstSlide}
				arrowWidth={arrowWidth}
				arrowHeight={arrowHeight}
			>
				<ImageAva src={product.imgSliderIconWoman?.replace('http:', 'https:')} alt='Nerv' style={chooseMan ? {opacity: "0.3"} : {opacity: "1"}} />
			</ChoseWomanPrev>
		);
	};

	const ChoseButtonMan = () => {
		const swiper = useSwiper();

		const handleClickNext = () => {
			let change = activeSlideNumber
			productSlider.forEach(()=> {
				if (change>0) {
					change-=1
				}
				swiper.slidePrev();
			})
			setActiveSlideNumber(change)
			setBorderLeft("67.9%")
			setChooseMan(true);
		};

		return (
			<ChoseManPrev
			viewBox='0 0 9 16'
			onClick={() => handleClickNext()}
			lastSlide={lastSlide}
			arrowWidth={arrowWidth}
			arrowHeight={arrowHeight}
			>
			<ImageAva src={product.imgSliderIconMan?.replace('http:', 'https:')} alt='Nerv' style={chooseMan ? {opacity: "1"} : {opacity: "0.3"}} />
			</ChoseManPrev>
		);
	};


	const SwiperButtonPrev = () => {
		const swiper = useSwiper();
		const handleClickPrev = () => {
			swiper.slidePrev();
			setActiveSlideNumber(activeSlideNumber-1)
			if (activeSlideNumber-1<product.imgSlider.length) {
				setBorderLeft("67.9%")
				setChooseMan(true);
			}

		};

		return (
			<ArrowPrev
				viewBox='0 0 9 16'
				onClick={() => handleClickPrev()}
				firstSlide={firstSlide}
				arrowWidth={arrowWidth}
				arrowHeight={arrowHeight}
			>
				<path d='M8.95001 13.09H6.84003V15.2H8.95001V13.09Z' />
				<path d='M6.84003 10.98H4.72998V13.09H6.84003V10.98Z' />
				<path d='M4.71997 8.85999H2.60999V10.97H4.71997V8.85999Z' />
				<path d='M2.60999 6.75H0.5V8.85999H2.60999V6.75Z' />
				<path d='M4.71997 4.64001H2.60999V6.75H4.71997V4.64001Z' />
				<path d='M6.84003 2.52002H4.72998V4.63H6.84003V2.52002Z' />
				<path d='M8.95001 0.409973H6.84003V2.52002H8.95001V0.409973Z' />
			</ArrowPrev>
		);
	};

	const SwiperButtonNext = () => {
		const swiper = useSwiper();

		const handleClickNext = () => {
			swiper.slideNext();
			setActiveSlideNumber(activeSlideNumber+1)
			if (activeSlideNumber+1>=product.imgSlider.length) {
				setBorderLeft("70.45%")
				setChooseMan(false);
			}

		};

		return (
			<ArrowNext
			viewBox='0 0 9 16'
			onClick={() => handleClickNext()}
			lastSlide={lastSlide}
			arrowWidth={arrowWidth}
			arrowHeight={arrowHeight}
			>
				<path d='M2.53003 0.619995H0.420044V2.72998H2.53003V0.619995Z' />
				<path d='M4.64001 2.73999H2.53003V4.84998H4.64001V2.73999Z' />
				<path d='M6.75 4.84998H4.64001V6.96002H6.75V4.84998Z' />
				<path d='M8.85999 6.96002H6.75V9.07001H8.85999V6.96002Z' />
				<path d='M6.75 9.07001H4.64001V11.18H6.75V9.07001Z' />
				<path d='M4.64001 11.19H2.53003V13.3H4.64001V11.19Z' />
				<path d='M2.53003 13.3H0.420044V15.41H2.53003V13.3Z' />
			</ArrowNext>
		);
	};

	return (
		<Swiper
			modules={[Mousewheel]}
			mousewheel={{ releaseOnEdges: true }}
			direction={'horizontal'}
			spaceBetween={50}
			slidesPerView={1}
			scrollbar={{ draggable: true }}
			onSlideChange={swiper => {
				swiper.activeIndex === swiper.slides.length - 1
					? setLastSlide(true)
					: setLastSlide(false);
				if (swiper.activeIndex === 0) {
					setFirstSlide(true)
					setBorderBottom("4%")
				}else {
					setFirstSlide(false);
					setBorderBottom("12.5%")
				}
			}}
			onSlidePrevTransitionStart={() => {
				setCurrentSlide(prev =>
					prev <= 10 ? '0' + (Number(prev) - 1) : Number(prev) - 1
				);
			}}
			onSlideNextTransitionStart={() => {
				setCurrentSlide(prev =>
					prev < 9 ? '0' + (Number(prev) + 1) : Number(prev) + 1
				);
			}}
		>
			{productSlider?.map((item, index) => (
				<SwiperSlide key={item + index}>
					<Image src={item.replace('http:', 'https:')} alt='Nerv' />
				</SwiperSlide>
			))}
			{product?.imgSliderAppearance && product?.imgSliderAppearanceWoman && width>1100 ? (
				<>
					{/*<ChosenBorderContainer style={{bottom: borderBottom}}>*/}

						
						<ChoseButtonMan />
						<ChoseButtonWoman />


						<ChoseFirst />
						<ChoseSecond />
				</>
			) : null}

			{product?.imgSliderAppearance && product?.imgSliderAppearanceWoman && width<=1100 ? (
				<>
					{/*<ChosenBorderContainer style={{bottom: borderBottom}}>*/}

						<ChosenBorderContainer  style={chooseMan ? {top: "77%"} : {top: "88%"}} >
							<ChosenBorderTop>
								<ChosenBorderTopLeft/>
								<ChosenBorderTopRight/>
							</ChosenBorderTop>
							<ChosenBorderBot>
								<ChosenBorderBotLeft/>
								<ChosenBorderBotRight/>
							</ChosenBorderBot>
						</ChosenBorderContainer>
						<ChoseButtonMan />
						<ChoseButtonWoman />


						<ChoseFirst />
						<ChoseSecond />
				</>
			) : null}
				<>
					<SwiperButtonPrev />
					<SwiperButtonNext />
				</>
		</Swiper>
	);
};
