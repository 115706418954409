import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import {
	PayPalScriptProvider,
	PayPalButtons,
	usePayPalScriptReducer,
} from '@paypal/react-paypal-js';

import api from '../../services/apiCalls.service';

const style = {
	layout: 'vertical',
	color: 'black',
	height: 46,
};

// Custom component to wrap the PayPalButtons and show loading spinner
const ButtonWrapper = ({ showSpinner, cartData, orderId }) => {
	const [{ isPending }] = usePayPalScriptReducer();
	const navigate = useNavigate();

	async function createOrder(data, actions) {
		return actions.order.create({
			purchase_units: [{ amount: { value: cartData.cart.total.toFixed(2) } }],
			custom_id: orderId,
		});
	}

	async function onApprove(data) {
		const success = await api.captureOrder(orderId, data);

		setTimeout(() => {
			navigate('/order-granted');
		}, 1e3);

		return success;
	}

	return (
		<>
			{((showSpinner && isPending) || !orderId) && <div className='spinner' />}
			<PayPalButtons
				style={style}
				disabled={false}
				forceReRender={[style]}
				fundingSource={'paypal'}
				createOrder={createOrder}
				onApprove={onApprove}
			/>
		</>
	);
};

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 4.6rem;
	cursor: pointer;
	border: 1px solid transparent;
	transition: all 0.2s ease;

	& > span {
		font-weight: 450;
		font-size: ${props => props.theme.fontsm};
		line-height: 1.8rem;
		color: #adadad;
		transition: all 0.2s ease;
	}
	&:hover {
		background: transparent;
		border: 1px solid ${props => props.theme.colorMain};
		& > span {
			color: ${props => props.theme.colorMain};
		}
	}
	@media screen and (max-width: 1100px) {
		height: 6rem;
		& > span {
			font-size: ${props => props.theme.fontMobBut};
			line-height: 2.2rem;
		}
	}
`;

function FondyPaymentButton({ cartData }) {
	const [orderId, setOrderId] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [but_disabled, setBut_disabled] = useState(false);

	async function create_Invoice(body) {
		const token = 'mdFzm8rIJfesYZlwF_WVGdg';
		// mdFzm8rIJfesYZlwF_WVGdg  // рабочий
		// uudVU6_4JSxDxUYUg_Vc__vjN71KmEDbg-HNS-v6nMXk  //тестовый

		const responseMB = await fetch('https://api.monobank.ua/api/merchant/invoice/create', {
		  method: 'POST',
		  headers: {
			'Content-Type': 'application/json',
			'x-token': token,
		  },
		  body: JSON.stringify(body),
		})

		return await responseMB.json();

		//window.location.href = respMsg.pageUrl
	  }

	async function msg_to_tg(body) {
		const responseTG = await fetch('https://nerv-api.vercel.app/api/telegram', {
			method: 'POST',
			mode: 'no-cors',
			headers: {
			'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		})

		return await responseTG;
	}

	async function get_course() {

		const responseCourse = await fetch('https://nerv-api.vercel.app/api/get_course', {
			method: 'GET',
			headers: {
			  'Content-Type': 'application/json'
			},
		  })
		return await responseCourse.json();
	}
	async function save_new_course() {
		const responseCourse = await fetch('https://nerv-api.vercel.app/api/save_new_course', {
			method: 'GET',
			mode: 'no-cors',
			headers: {
			'Content-Type': 'application/json',
			},
		})

		return await responseCourse.json();
	}

	const handlePayButtonClick = React.useCallback(async (event) => {
		event.preventDefault();
		setBut_disabled(true);

		setErrorMessage(false);

		let usedPromo = false;
		/*
		if (usedPromo) {
			const increaseUsePromoCode = api.increaseUsePromoRequest({code: cartData.promoCodeData.code});
			//const increaseUsePromoCode = await api.increaseUsePromoRequest({code: cartData.promoCodeData.code});
		}
		*/

		//++

		const savingResult = await api.saveShoppingCart(cartData);

		if (savingResult.error) {
			setErrorMessage('Failed to generate payment link :( Please try again');
			return;
		}

		if (!savingResult.checkoutUrl & false) {
			setErrorMessage(
				'Failed to generate payment link for Fondy :(. Please try again'
			);
			return;
		}

		setOrderId(savingResult.orderId);


		// ++
		const course = await get_course();
		//const course = 41.58;
		console.log(`курс =  ${course}`);
		//console.log(course);




		function totalResult(cart = undefined, mainEventDiscount = undefined, promoCodeDiscount = undefined, dataReturn = 'total', index = 0) {
			let total_priceWithEventDiscount = 0;
			let total_priceWithPromoCode = 0;
			let total_priceWithProductDiscount = 0;
			let total_sum_price = 0;
			let best_discount = ''
			let promoDiscont = parseInt(promoCodeDiscount)

			let total_result = 0;

			if (cart) {
				cart.products.forEach((el , index)=>{
					if (el.priceWithDiscount!=='' & el.priceWithDiscount > 0) {
						total_priceWithProductDiscount+=(el.priceWithDiscount*el.quantity);
					}
					else {
						total_priceWithProductDiscount+=(el.price*el.quantity);
					}
					total_sum_price+=el.price*el.quantity;
				})
			}

			if (total_priceWithProductDiscount < total_sum_price)
				best_discount = 'ProductDiscount'

			if (mainEventDiscount != undefined) {
				total_priceWithEventDiscount = total_sum_price*(100-mainEventDiscount)/100;
				if (total_priceWithEventDiscount < total_sum_price & total_priceWithEventDiscount < total_priceWithProductDiscount)
					best_discount = 'EventDiscount'
			}
			if (promoCodeDiscount != undefined) {
				total_priceWithPromoCode = total_sum_price*(100-promoDiscont)/100;

				if (total_priceWithPromoCode < total_sum_price & total_priceWithPromoCode < total_priceWithProductDiscount ) {
					if (mainEventDiscount != undefined) {
						if (total_priceWithPromoCode < total_priceWithEventDiscount) {
							best_discount = 'promoCodeDiscount'
						}
					}
					else {
						best_discount = 'promoCodeDiscount'
					}
				}
			}

			if (best_discount === '')
				total_result = total_sum_price

			if (best_discount === 'ProductDiscount')
				total_result = total_priceWithProductDiscount

			if (best_discount === 'EventDiscount')
				total_result = total_priceWithEventDiscount

			if (best_discount === 'promoCodeDiscount')
				total_result = total_priceWithPromoCode

			if (dataReturn === 'total')	{
				return (total_result)
			}
			if (dataReturn === 'subtotal')	{
				return (total_sum_price)
			}

			if (dataReturn === 'price')	{
				if (best_discount === '') {
						total_result = cart.products[index].price
				}

				if (best_discount === 'ProductDiscount') {
						total_result = cart.products[index].priceWithDiscount
				}

				if (best_discount === 'EventDiscount') {
					total_result = cart.products[index].price*(100-mainEventDiscount)/100;
				}

				if (best_discount === 'promoCodeDiscount') {
					usedPromo = true;
					total_result = cart.products[index].price*(100-promoDiscont)/100;
				}

				return (total_result)
			}
			return (total_result)

		}


		console.log(cartData);
		console.log(`заказ ${savingResult.orderId}`);
		//const total_cost = Math.round(cartData.cart.total*100*course);
		const total_cost = Math.round(totalResult(cartData.cart, cartData.mainEvent.discont, cartData.promoCodeData.discont, 'total')*100*course);

		let mass = [];
		let summ_rpice = 0;
		cartData.cart.products.forEach((el, index) => {
			summ_rpice+=el.price;
			let size_full = "";
			let size_short = "";
			if (el.description.sizeFull != "") {size_full = `/ ${el.description.sizeFull}`}
			if (el.description.sizeShort != "") {size_short = `(${el.description.sizeShort})`}
			mass.push({
			"name": ` ${el.name}  / ${el.model} / ${el.description.color} ${size_full}${size_short} / ${el.desc}`,
			"qty": el.quantity,
			"sum": Math.round(totalResult(cartData.cart, cartData.mainEvent.discont, cartData.promoCodeData.discont, 'price', index)*100*course),
			"icon": el.imgGear,
			"code": el.specificId,
			"unit": "шт.",
			"barcode": "",
			"header": el.desc,
			"footer": `${el.model} / ${el.description.color} / ${el.description.sizeFull} `,
			})
		});
		// "code": el._id,

		if (summ_rpice < cartData.cart.total) {
			const shipping_price = Math.round((cartData.cart.total - summ_rpice)*100*course);
			mass.push({
				"name": ` SHIPPING`,
				"qty": 1,
				"sum": shipping_price,
				"code": "553377",
				"unit": " ",
				"barcode": "",
				"footer": "Delivery of the remaining goods in the order",
				});
			//"icon": "https://i.postimg.cc/tJ9SgbkH/2da3b792d602f4254ca9ff29827600ed.webp",

		}

		const body_to_req = {
			amount: total_cost,
			ccy: 980,
			merchantPaymInfo: {
			  "reference": `${savingResult.orderId}`,
			  "destination": "Оплата nervcorporation.com",
			  "comment": `Оплата заказа ${savingResult.orderId}`,
			  "basketOrder": mass,
			  "customerEmails": [cartData.contactInfo.email]
			},
			redirectUrl: "https://nervcorporation.com/",
			webHookUrl: "https://nerv-api.vercel.app/api/take_message",
			validity: 3600,
		   };

		//https://nervcorporation.com/api/shopping_cart/order_callback
		console.log(body_to_req);
		//++
		const res_create_invoice = await create_Invoice(body_to_req);

		const body_to_req_tg = {
			status: "my_create",
			amount: totalResult(cartData.cart, cartData.mainEvent.discont, cartData.promoCodeData.discont, 'total'),
			ccy: 840,
			merchantPaymInfo: {
			  "reference": `${savingResult.orderId}`,
			  "destination": "Оплата nervcorporation.com",
			  "comment": `Оплата заказа ${savingResult.orderId}`,
			  "basketOrder": mass
			},

		   };
		//++
		msg_to_tg(body_to_req_tg);
		save_new_course();

		if (usedPromo) {
			const increaseUsePromoCode = api.increaseUsePromoRequest({code: cartData.promoCodeData.code});
			//const increaseUsePromoCode = await api.increaseUsePromoRequest({code: cartData.promoCodeData.code});
		}
		//console.log(`link ${res_create_invoice.pageUrl}`);
		//setBut_disabled(false);
		//++
		localStorage.removeItem('promoCode');

		window.location.href = res_create_invoice.pageUrl;

		setTimeout(() => {
			setBut_disabled(false);
		}, "5000");
	}, []);

	return (
		<React.Fragment>
			{errorMessage && <div style={{ fontSize: 'red' }}>{errorMessage}</div>}

			<Button onClick={handlePayButtonClick} disabled={but_disabled}>
				<span>PAY VIA MONOBANK </span>
			</Button>

			{/* <div style={{ height: '10px' }}></div>

			<div style={{ maxWidth: '750px', width: '100%', margin: 'auto' }}>
				<PayPalScriptProvider
					options={{ clientId: 'test', components: 'buttons', currency: 'USD' }}
				>
					<ButtonWrapper
						cartData={cartData}
						orderId={orderId}
						showSpinner={false}
					/>
				</PayPalScriptProvider>
			</div> */}
		</React.Fragment>
	);
}

export default FondyPaymentButton;
