import React from 'react';
import styled from 'styled-components';
import previewBg from '../../assets/preview-order-bg.svg';
import previewBgTab from '../../assets/preview-order-bg-tab.svg';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteProduct } from '../../redux/cartRedux';
import { OrderPrice } from './OrderPrice';

const ContentMainItemRemove = styled.div`
	position: absolute;
	right: 1.8rem;
	top: 1.4rem;
	z-index: 13;



	@media screen and (max-width: 1100px) {
		right: 9.8rem !important;
		font-weight: 450;
		font-size: ${props => props.theme.fontMob};
		line-height: 2.2rem;
	}

	cursor: pointer;
	& > svg {
		width: 100%;
		height: 100%;
		fill: none;
		& > path {
			stroke: ${props => props.theme.colorMain};
			transition: all 0.2s ease;
		}
	}

	&:hover {
		path {
			stroke: #fff !important;
		}
	}
`;

// const ContentPrice = styled.div`
// 	display: flex;
// 	justify-content: space-between;
// 	align-items: center;
// 	border-top: 1px solid ${props => props.theme.colorBorder};
// 	padding: 3rem 3.3rem;
// 	width: 100%;
// 	height: 13.9rem;
// 	@media screen and (max-width: 1100px) {
// 		height: 100%;
// 	}
// `;

// const ContentPriceItem = styled.div`
// 	&:last-child {
// 		text-align: right;
// 	}
// 	& > div {
// 		font-weight: 400;
// 		font-size: 1.2rem;
// 		line-height: 1.8rem;
// 		color: ${props => props.theme.colorMain};
// 		margin-bottom: 0.2rem;
// 		&:last-child {
// 			font-weight: 600;
// 			margin-bottom: 0;
// 		}
// 	}
// `;

const Tablet = styled.div`
	display: none;
	@media screen and (max-width: 1100px) {
		display: block;
		margin: 6rem 0 7rem;
		width: 100%;
		height: 100%;
	}
	@media screen and (max-width: 567px) {
		margin: 6.3rem 0 5rem;
	}
`;

const TabletTop = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 2rem;
	padding-top: 2rem;
	width: 100%;
	border-top: 1px solid ${props => props.theme.colorBorder};

	@media screen and (max-width: 1100px) {
		border: none !important;
		padding-top: 1.3rem !important;
		width: 92.5%;
	}
	& > a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 95%;
		margin: auto;
		text-align: center;
		background: ${props => props.theme.colorMain};
		padding-top: 0.1rem;
		height: 4.6rem;
		transition: all 0.2s ease;

		& > span {
			font-weight: 450;
			font-size: ${props => props.theme.fontMob};
			line-height: 2.2rem;
			color: #adadad;
			transition: all 0.2s ease;
		}
		&:hover {
			background: transparent;
			border: 1px solid ${props => props.theme.colorMain};
			& > span {
				color: ${props => props.theme.colorMain};
			}
		}
		@media screen and (max-width: 1100px) {
			border: none !important;
			height: 6rem;
		}
		@media screen and (max-width: 567px) {
			border: none !important;
			& > span {
				font-size: ${props => props.theme.fontMob};
				line-height: 2.2rem;
			}
		}
	}
`;

const TabletBottom = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1.8rem;
	border-top: 1px solid ${props => props.theme.colorBorder};

	@media screen and (max-width: 1100px) {
		border: none !important;
		width: 100%;
	}
`;

const TabletBottomItem = styled.div`
	position: relative;
	display: flex;
	border-bottom: 1px solid ${props => props.theme.colorBorder};
	height: 33rem;
	@media screen and (max-width: 1100px) {
		border: none !important;
		width: 100%;
		height: 20rem;
	}
	@media screen and (max-width: 567px) {
		height: 20rem;
	}
	${ContentMainItemRemove} {
		top: 1.1rem;
		right: 5.1rem;
		@media screen and (max-width: 1100px) {
			right: 3.1rem;
			width: 2rem;
			height: 2rem;
		}
		@media screen and (max-width: 567px) {
			right: 3.1rem;
			width: 2rem;
			height: 2rem;
		}
	}
`;

const TabletBottomLeft = styled.div`
	background: url(${previewBg}) 50% 50% no-repeat;
	background-size: 75%;
	width: 35%;
	padding: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	& > img {
		max-width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: center;
	}
	@media screen and (max-width: 1110px) {
		background: none;
		padding: 0 0 0 3rem;
		width: 25%;
	}
`;

const TabletBottomRight = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	border-left: 1px solid ${props => props.theme.colorBorder};
	padding: 1rem 2rem 1rem 4rem;
	@media screen and (max-width: 1100px) {
		border: none !important;
		margin-top:2.8rem;
		width:73%;
	}
	@media screen and (max-width: 567px) {
		padding: 1rem 2rem 1rem 2rem;
	}
`;

const ImageProduct = styled.img``;

const TabletName = styled.div`
	font-size: 2.6rem;
	line-height: 3.3rem;
	color: ${props => props.theme.colorMain};
	@media screen and (max-width: 1100px) {
		font-size: ${props => props.theme.fontMob};
		line-height: 2.2rem;
		font-weight: 500;
	}
`;
const TabletDesc = styled.div`
	font-size: ${props => props.theme.fontmd};
	line-height: 2.2rem;
	color: ${props => props.theme.colorMain};
	@media screen and (max-width: 1100px) {
		display:none;
	}
	@media screen and (max-width: 567px) {
		font-size: 1.4rem;
		line-height: 1.8rem;
	}
`;
const TabletType = styled.div`
	display: flex;
	justify-content: space-between;
	font-size: ${props => props.theme.fontmd};
	line-height: 2.2rem;
	color: ${props => props.theme.colorMain};
	text-transform: uppercase;
	margin: 3.6rem 0 3.2rem;
	@media screen and (max-width: 567px) {
		margin: 1.2rem 0 0rem;
		font-size: ${props => props.theme.fontMob};
		line-height: 2.2rem;
		font-weight: 500;
	}
`;
const TabletInStock = styled.div`
	font-size: ${props => props.theme.fontmd};
	line-height: 2.2rem;
	color: ${props => props.theme.colorMain};
	text-transform: uppercase;
	margin-bottom: 1rem;
	@media screen and (max-width: 1100px) {
		margin-bottom: 0.6rem;
	}
	@media screen and (max-width: 567px) {
		margin: 1.2rem 0 0rem;
		font-size: ${props => props.theme.fontMob};
		line-height: 2.2rem;
		font-weight: 500;
	}
`;
const TabletColor = styled.div`
	font-size: ${props => props.theme.fontmd};
	line-height: 2.2rem;
	color: ${props => props.theme.colorMain};
	text-transform: uppercase;
	margin-bottom: 1rem;
	@media screen and (max-width: 1100px) {
		margin-bottom: 0.6rem;
	}
	@media screen and (max-width: 567px) {
		font-size: 1.4rem;
		line-height: 1.8rem;
	}
`;
const TabletSize = styled.div`
	font-size: ${props => props.theme.fontmd};
	line-height: 2.2rem;
	color: ${props => props.theme.colorMain};
	text-transform: uppercase;
	margin-bottom: 1rem;
	@media screen and (max-width: 1100px) {
		margin-bottom: 0.6rem;
	}
	@media screen and (max-width: 567px) {
		font-size: 1.4rem;
		line-height: 1.8rem;
	}
`;
const TabletQuantity = styled.div`
	display:flex;
	align-items: center;
	justify-content:space-between;
	font-size: ${props => props.theme.fontmd};
	line-height: 2.2rem;
	color: ${props => props.theme.colorMain};
	text-transform: uppercase;
	@media screen and (max-width: 1100px) {
		margin: 1.2rem 0 0rem;
		font-size: ${props => props.theme.fontMob};
		line-height: 2.2rem;
		font-weight: 500;
		width: 66%;
	}

`;

const TabletCover = styled.div`
	display: flex;
	border-top: 1px solid ${props => props.theme.colorBorder};
	@media screen and (max-width: 1100px) {
		border: none !important;

	}
`;

const TabletCoverLeft = styled.div`
	position: relative;
	width: 35%;
	padding-left: 3.2rem;
	border-right: 1px solid ${props => props.theme.colorBorder};
	padding-top: 3rem;
	padding-bottom: 0.7rem;

	& > span {
		font-weight: 400;
		font-size: ${props => props.theme.fontMob};
		line-height: 2.2rem;
		color: ${props => props.theme.colorBlack};
		text-transform: uppercase;
	}
	@media screen and (max-width: 1100px) {
		border: none !important;
		width: 25%;
	}
	@media screen and (max-width: 567px) {
		padding-top: 3.3rem;
	}
`;

const TabletCoverRight = styled.div`
	width: 65%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 3rem 5.2rem 0.7rem 2rem;
	@media screen and (max-width: 1100px) {
		padding: 3.3rem 3.2rem 0.7rem 2rem;
		width: 75%;
	}
	& > span {
		font-weight: 400;
		font-size: ${props => props.theme.fontMob};
		line-height: 2.2rem;
		color: ${props => props.theme.colorBlack};
		text-transform: uppercase;
	}
	@media screen and (max-width: 567px) {
		padding: 3.3rem 3.2rem 0.7rem 2rem;
	}
`;

const TabletPrice = styled.div`
	border-bottom: 1px solid ${props => props.theme.colorBorder};
	@media screen and (max-width: 1100px) {
		border: none !important;

	}
	& > div {
		width: 100%;
		border-top: unset;
		@media screen and (max-width: 1100px) {
			border: none !important;

		}
		@media screen and (max-width: 567px) {
			padding: 2.3rem 4rem;
		}
	}
`;



const FixedBox = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;


const TabletBox = styled.div`
	display: flex;
	flex-direction: column;

	@media screen and (max-width: 1100px) {
		flex-direction: column-reverse;
		justify-content: center;
		align-items:center;
		width:100%;
	}
`;

const TablePrice = styled.div`

	@media screen and (max-width: 1100px) {
		font-weight: 700;
		font-size: ${props => props.theme.fontMob};
		line-height: 2.2rem;
	}
`;

const GearPrice = styled.div`
	display:flex;
	justify-content: flex-end;
	width: 50%;
	height: 100%;
	font-size: 1.2rem;
	line-height: 1.8rem;
	font-family: Fira Code;
	font-weight: 600;
	text-align: right;
	@media screen and (max-width: 1100px) {
		font-size: ${props => props.theme.fontMob};
		line-height: 3rem;
		font-weight: 700;
	}
`;

export const OrderItemTablet = ({ setShowFirstItem, mainEvent }) => {
	const cart = useSelector(state => state.cart);
	const dispatch = useDispatch();

	const handleDelete = (specificId, quantity, price) => {
		dispatch(deleteProduct({ specificId, quantity, price }));
		setShowFirstItem('showFirstItem');
	};

	const TotalPrice = ({price, priceWithDiscount = undefined, mainEventDiscount = undefined}) => {
		let priceWithEventDiscount = undefined;

		if (mainEventDiscount)
			priceWithEventDiscount = price*(100-mainEventDiscount)/100

		if (priceWithDiscount!=undefined & priceWithDiscount!== '' & priceWithDiscount!== 0 & mainEventDiscount!=undefined) {
			if (priceWithEventDiscount < priceWithDiscount) {
				return (
						<GearPrice>${priceWithEventDiscount} <div style={{textDecoration: 'line-through', opacity: '0.5', marginLeft:'1rem'}}>${price}</div></GearPrice>
				)
			}
			else {
				return (
						<GearPrice>${priceWithDiscount} <div style={{textDecoration: 'line-through', opacity: '0.5', marginLeft:'1rem'}}>${price}</div></GearPrice>
				)
			}
		}
		if (priceWithDiscount) {
			return (
					<GearPrice>${priceWithDiscount} <div style={{textDecoration: 'line-through', opacity: '0.5', marginLeft:'1rem'}}>${price}</div></GearPrice>
			)
		}
		if (mainEventDiscount) {
			return (
					<GearPrice>${priceWithEventDiscount} <div style={{textDecoration: 'line-through', opacity: '0.5', marginLeft:'1rem'}}>${price}</div></GearPrice>
			)
		}
		return (
				<GearPrice>${price}</GearPrice>
		)
	}

	return (
		<Tablet>
			<TabletCover>
				<TabletCoverLeft>
					<span>IMG</span>
				</TabletCoverLeft>
				<TabletCoverRight>
					<span>INFO</span>
					<span>DEL</span>
				</TabletCoverRight>
			</TabletCover>
			<TabletBox>
				<TabletTop>
					{/*
						<Link to='/'>
							<span>COMPLETE THE SET</span>
						</Link>
						*/}

					<Link
						to='/checkout'
						style={{
							pointerEvents: cart.products.length > 0 ? 'auto' : 'none',
							filter: cart.products.length > 0 ? 'unset' : 'blur(1px)',
						}}
					>
						<span>CHECKOUT</span>
					</Link>
				</TabletTop>
				<FixedBox>
					<TabletBottom>
						{cart.products?.map(item => (
							<TabletBottomItem key={item.specificId}>
								<ContentMainItemRemove
									onClick={() =>
										handleDelete(item.specificId, item.quantity, item.price)
									}
								>
									{'[REMOVE]'}
								</ContentMainItemRemove>
								<TabletBottomLeft>
									<ImageProduct
										src={item.imgMain?.replace('http:', 'https:')}
										alt={item.name}
									/>
								</TabletBottomLeft>
								<TabletBottomRight>
									<TabletName>{item.name}</TabletName>
									<TabletDesc>{item.desc}</TabletDesc>
									<TabletType>
										<div>{item.model} / {item.description.color} / {item.description.sizeFull}</div>
										<TablePrice>${item.price}</TablePrice>
									</TabletType>
									<TabletQuantity><div>QUANTITY:</div><div>{item.quantity}</div></TabletQuantity>
									<TabletInStock>
										ONLY {item.description.inStock} ITEM LEFT
									</TabletInStock>
								</TabletBottomRight>
							</TabletBottomItem>
						))}
					</TabletBottom>
					<TabletPrice>
						<OrderPrice mainEvent={mainEvent} />
					</TabletPrice>
				</FixedBox>
			</TabletBox>
		</Tablet>
	);
};
