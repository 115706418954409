import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { ShuffleLetters } from '../../interface/ShuffleLetters';
import { useSelector } from 'react-redux';
import apiCallsService from '../../services/apiCalls.service';

const GearWrapper = styled.div`
	position: relative;
	border-left: 0px solid ${props => props.theme.colorBorder};
	max-width: 120.9rem;
	width: 100%;
	height: 100%;

	@media screen and (max-width: 1100px) {

		width: 100%;
		max-width: 100%;
		border-left: unset;
	}
	@media screen and (max-width: 567px) {
		width: 100%;
	}
`;

const GearTop = styled.div`
	height: 7.8rem;
	border-bottom: 0px solid ${props => props.theme.colorBorder};
	font-weight: 400;
	font-size: 1.2rem;
	line-height: 1.8rem;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	padding-left: 2rem;
	color: #000000;
	@media screen and (max-width: 1100px) {
		display: none;
	}
`;

const GearMainViewApp = styled.span`
	display: none;
	@media screen and (max-width: 1100px) {
		display: inline;
		margin-top:2.1rem;
		margin-right: 1.1rem;
		display: inline;
		color: ${props => props.theme.colorBlack} !important;
		font-weight: ${props => (props.showAllGear ? '900' : '500')};
		font-size: ${props => props.theme.fontMob};
		line-height: 2rem;
		cursor: pointer;
		&:hover {
			font-weight: 900;
		}
		z-index:6;
	}
`;

const GearMainViewDesk = styled.span`
	width:100%;
	padding-right: 4.7rem;
	display: flex;
	justify-content: space-between;
`;

const GearButtonShowAll = styled.span`
	font-family: Fira Code;
	font-size: 1.2rem;
	font-weight: 600;
	line-height: 1.8rem;
	text-align: left;
	color: #000000;
	cursor: pointer;
`;

const GearMainView = styled.span`
	font-weight: 400;
	font-size: 1.2rem;
	line-height: 1.8rem;
	text-transform: uppercase;
	margin-bottom: 1rem;
	padding-left: 0.1rem;
	& > span {
		color: ${props => props.theme.colorGray};
	}

	@media screen and (max-width: 1100px) {

		& > span {
			&:first-child {
				display: none;
			}
		}
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1rem;
		font-size: 1.4rem;
		padding: 0.9rem 2.5rem 0.6rem 2rem;
	}
	@media screen and (max-width: 567px) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1rem;
		font-size: 1.4rem;
		padding: 0.9rem 2.5rem 0.6rem 2rem;
	}
`;

const GearMainViewType = styled.span`
	color: ${props => props.theme.colorBlack} !important;
`;

const GearMainViewMob = styled.span`
	display: none;
	@media screen and (max-width: 1100px) {
		margin-top:1.9rem;
		display: inline;
		color: ${props => props.theme.colorBlack} !important;
		font-weight: ${props => (props.showAllGear ? '900' : '600')};
		font-size: ${props => props.theme.fontMob};
		line-height: 2rem;
		cursor: pointer;
		&:hover {
			font-weight: 900;
		}
		z-index:6;
	}
	@media screen and (max-width: 567px) {
		margin-top:2.1rem;
		display: inline;
		color: ${props => props.theme.colorBlack} !important;
		font-weight: ${props => (props.showAllGear ? '900' : '600')};
		font-size: ${props => props.theme.fontMob};
		line-height: 2rem;
		cursor: pointer;
		&:hover {
			font-weight: 900;
		}
		z-index:6;
	}
`;

const GearMain = styled.div`
	display: flex;
	flex-direction: column;
	padding: 3.1rem 0 0rem 0;
	height: calc(100%);
	margin-right: 0.2rem;
	@media screen and (max-width: 1100px) {
		height: 100%;
		padding-top: 0.6rem;
		margin-right: 0;
	}
	@media screen and (max-width: 567px) {
		padding-top: 0;
	}
`;

const GearBlock = styled.div`
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	padding: 1rem 1.7rem 0 0.1rem;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 0.4rem;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 12px;
		background-color: ${props => props.theme.colorBlack};
	}
	@media screen and (max-width: 1100px) {

		gap: 2rem;
		padding: 1rem 1rem 0;
		margin: 0 1.8rem 0 0.5rem;
	}
	@media screen and (max-width: 567px) {
		overflow-y: hidden;
		padding-top: 0rem;
		gap: 4rem;
	}
`;

const GearCover = styled.div`
	display: flex;
	flex-direction:column;
	justify-content: center;
	align-items: start;
	padding: 0 0 0 0;
	opacity: ${props => (props.activeitem ? '1' : ' 0.5')};
	width: 100%;
	height: 100%;
	transition: all 0.2s ease;
	@media screen and (max-width: 1100px) {
		width: 100%;
		margin: auto;
		//padding: 0.5rem;
	}
	@media screen and (max-width: 567px) {
		padding: 0 0;
	}
`;

const GearLineInfoFirst = styled.div`
	margin-top: 1.45rem;
	margin-left: 1.28rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 95.65%;
	height: 1.8rem;
	font-size: 1.2rem;
	line-height: 1.8rem;
	font-family: Fira Code;
	font-weight: 600;
	text-align: left;


`;

const GearLineInfoSecond = styled.div`
	margin-top: 0.76rem;
	margin-left: 1.28rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 95.65%;
	height: 1.8rem;
	font-size: 1.2rem;
	line-height: 1.8rem;
	font-family: Fira Code;
	font-weight: 400;
	text-align: left;

	@media screen and (max-width: 1100px) {
		margin-top: 1.9rem;
		font-size: 1.8rem;
		line-height: 3rem;
		font-weight: 500;
	}
`;

const GearName = styled.div`
	width: 50%;
	height: 100%;
	font-size: 1.2rem;
	line-height: 1.8rem;
	font-family: Fira Code;
	font-weight: 600;
	text-align: left;

	@media screen and (max-width: 1100px) {
		font-size: 1.8rem;
		line-height: 3rem;
		font-weight: 700;
	}
`;
const GearPrice = styled.div`
	display:flex;
	justify-content: flex-end;
	width: 50%;
	height: 100%;
	font-size: 1.2rem;
	line-height: 1.8rem;
	font-family: Fira Code;
	font-weight: 600;
	text-align: right;
	@media screen and (max-width: 1100px) {
		font-size: 1.8rem;
		line-height: 3rem;
		font-weight: 700;
	}
`;

const Image = styled.img`
	object-fit: contain;
	object-position: center;
	width: 100%;
	height: 29.35rem;

	@media screen and (max-width: 1100px) {
		height: 50.35rem;
	}
`;

const GearStatus = styled.div`
	position: absolute;
	top: -0.65rem;
	right: 0.5rem;
	height: 1.4rem;
	width: 6.7rem;
	background: ${props => props.theme.colorMain};
	text-align: center;
	& > span {
		font-weight: 450;
		font-size: 1rem;
		line-height: 1.4rem;
		color: #adadad;
		text-transform: uppercase;
	}
	@media screen and (max-width: 1700px) {
		padding-top: 0.07rem;
	}
	@media screen and (max-width: 1100px) {
		padding-top: 0;
		& > span {
			line-height: 1.4rem;
		}
	}
	@media screen and (max-width: 567px) {
		top: -1.65rem;
		height: 3.4rem;
		width: 17.7rem;
		& > span {
			font-size: 2rem;
			line-height: 3.4rem;
		}
	}
`;

const GearAside = styled.div`
	position: absolute;
	left: -0.65rem;
	top: 0;
	width: 1.4rem;
	height: 100%;
	background: ${props => props.theme.colorMain};
	opacity: ${props => (props.activeitem ? '1' : ' 0')};
	transition: all 0.2s ease;
	@media screen and (max-width: 1100px) {
		height: 17.9rem;
		display: none;
	}
	@media screen and (max-width: 567px) {
		display: none;
	}
`;

const GearItem = styled.div`
	position: relative;
	//background: ${props => props.theme.colorBorder};
	max-width: 24.5%;
	width: 100%;
	height: 37.65rem;
	cursor: pointer;
	pointer-events: ${props => (props.absent ? 'none' : 'auto')};

	&:hover {
		${GearCover} {
			opacity: 1;
		}

		${GearAside} {
			opacity: 1;
		}
	}

	& > a {
		display: none;
	}

	@media (max-width: 1100px) {
		max-width: 46%;
		height: auto;
		& > a {
			position: relative;
			height: 3.6rem;
			padding: 0.1rem 2.4rem 0 1rem;
			background: ${props => props.theme.colorMain};
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 0.3rem;

			& > span {
				width: 50%;
				font-weight: 450;
				font-size: 1.1rem;
				line-height: 1.4rem;
				color: #adadad;
				text-transform: uppercase;
				&:nth-child(2) {
					text-align: right;
				}
			}
		}
	}
	@media screen and (max-width: 567px) {
		max-width: 100%;
		& > a {
			padding: 2rem 6.6rem 2rem 4rem;
			height: 6rem;
			& > span {
				font-size: 2rem;
				line-height: 2.2rem;
			}
			& > svg {
				right: 2rem;
				transform: translateY(-62%);
				width: 1.4rem;
				height: 2.3rem;
			}
		}
	}
`;

const IconArrow = styled.svg`
	position: absolute;
	right: 1rem;
	top: 50%;
	transform: translateY(-50%);
	width: 0.8rem;
	height: 1.7rem;
	fill: none;
	& > path {
		fill: #adadad;
		transition: all 0.2s ease;
	}
`;

const EmptyButton = styled.div`
	display: none;

	@media (max-width: 1100px) {
		display: none;
		position: relative;
		padding: 1.2rem 0;
		height: 3.6rem;
		background: ${props => props.theme.colorMain};
		text-align: center;
		& > span {
			font-weight: 450;
			font-size: 1.1rem;
			line-height: 1.4rem;
			color: #adadad;
			text-transform: uppercase;
			pointer-events: none;
		}
	}

	@media (max-width: 567px) {
		display: none;
		position: relative;
		padding: 2rem;
		height: 6rem;
		background: ${props => props.theme.colorMain};
		text-align: center;
		& > span {
			font-weight: 450;
			font-size: 2rem;
			line-height: 2.2rem;
			color: #adadad;
			text-transform: uppercase;
			pointer-events: none;
		}
	}
`;

export const Gear = ({
	itemType,
	activeitem,
	setActiveitem,
	setShowAllGear,
	showAllGear,
	setActiveelement,
	collectionFilter,
	setCollectionFilter,
}) => {
	const navigate = useNavigate();
	const [filterProducts, setFilterProducts] = useState([]);
	const [allProducts, setAllProducts] = useState([]);

	const [mainEvent, setMainEvent] = useState({});

	const data = useSelector(state => state.data);

	useEffect(() => {
		let filterByModel = data?.filter(item => item.model === itemType);
		const filterByAvailability = filterByModel.find(
			item => item.availability.toLowerCase() !== 'soon'
		);

		filterByAvailability && setActiveitem(filterByAvailability._id);

		if (collectionFilter.length>0)
			filterByModel = data?.filter(item => item.availability?.toUpperCase().includes(collectionFilter.toUpperCase()));

		setFilterProducts(filterByModel);
		setAllProducts(data);
		setActiveitem(filterByModel[0]?._id)


		try{
			async function fetchEventData() {
				const events_response = await apiCallsService.getEventsRequest();
				setMainEvent(events_response)

			}
			fetchEventData()
		}catch(e){}

	}, [itemType, data, setActiveitem, collectionFilter]);



	const handleClick = (id, name) => {
		setActiveitem(id);
		navigate(`/interface/${name
			.replace(/ /gi, '-')
			.toLowerCase()}`);
	};

	const viewAllGear = () => {
		setShowAllGear(true);
		setActiveelement();
	};

	const TotalPrice = ({price, priceWithDiscount = undefined, mainEventDiscount = undefined}) => {
		let priceWithEventDiscount = undefined;

		if (mainEventDiscount)
			priceWithEventDiscount = price*(100-mainEventDiscount)/100

		if (priceWithDiscount!=undefined & priceWithDiscount!== '' & priceWithDiscount!== 0 & mainEventDiscount!=undefined) {
			if (priceWithEventDiscount < priceWithDiscount) {
				return (
						<GearPrice>${priceWithEventDiscount} <div style={{textDecoration: 'line-through', opacity: '0.5', marginLeft:'1rem'}}>${price}</div></GearPrice>
				)
			}
			else {
				return (
						<GearPrice>${priceWithDiscount} <div style={{textDecoration: 'line-through', opacity: '0.5', marginLeft:'1rem'}}>${price}</div></GearPrice>
				)
			}
		}
		if (priceWithDiscount) {
			return (
					<GearPrice>${priceWithDiscount} <div style={{textDecoration: 'line-through', opacity: '0.5', marginLeft:'1rem'}}>${price}</div></GearPrice>
			)
		}
		if (mainEventDiscount) {
			return (
					<GearPrice>${priceWithEventDiscount} <div style={{textDecoration: 'line-through', opacity: '0.5', marginLeft:'1rem'}}>${price}</div></GearPrice>
			)
		}
		return (
				<GearPrice>${price}</GearPrice>
		)
	}

	return (
		<GearWrapper>
			<GearMain>
				<GearMainView>
					<GearMainViewDesk>
						<GearButtonShowAll onClick={viewAllGear}>[ SHOP ALL ]</GearButtonShowAll>
						<div>
							<span>MODEL LINE</span>{' '}
							<GearMainViewType>
								[ {showAllGear ? 'ALL' : itemType || collectionFilter} ]
							</GearMainViewType>
						</div>
					</GearMainViewDesk>
					<GearMainViewMob
						onClick={() => viewAllGear()}
						showAllGear={showAllGear}
					>
						<ShuffleLetters text='[ SHOP ALL ]' />
					</GearMainViewMob>{' '}
					<GearMainViewApp>
						<GearMainViewType>
							{'NEW IN'}
						</GearMainViewType>{' '}
					</GearMainViewApp>
				</GearMainView>
				<GearBlock>
					{showAllGear
						? allProducts.map(item => (
								<GearItem
									key={item._id}
									absent={item.availability === 'soon' ? true : false}
									onClick={() => handleClick(item._id, item.name)}
								>

										<GearCover
											activeitem={item._id === activeitem ? true : false}
										>
											<Image
												src={item.imgGear?.replace('http:', 'https:')}
												alt='Nerv'
											/>
											<GearLineInfoFirst>
												<GearName>{item.name}</GearName>
												<TotalPrice price={item.price} priceWithDiscount={item.priceWithDiscount}  mainEventDiscount={mainEvent.active ? mainEvent.discont : undefined} />
											</GearLineInfoFirst>
											<GearLineInfoSecond>{item.desc}</GearLineInfoSecond>
										</GearCover>

									{item.availability?.toLowerCase() === 'new' ||
									item.availability === '' ? (
										<Link

											style={{display:'none'}}

											to={`/interface/${item.name
												.replace(/ /gi, '-')
												.toLowerCase()}`}
										>
											<span>{item.name}</span>
											<span>${item.price}</span>
											<IconArrow viewBox='0 0 12 21'>
												<path d='M3.5 0.75H0.689941V3.56H3.5V0.75Z' />
												<path d='M6.31006 3.56006H3.5V6.37006H6.31006V3.56006Z' />
												<path d='M9.12988 6.38H6.31982V9.19H9.12988V6.38Z' />
												<path d='M11.9398 9.18994H9.12988V11.9999H11.9398V9.18994Z' />
												<path d='M9.12988 12H6.31982V14.81H9.12988V12Z' />
												<path d='M6.31006 14.8101H3.5V17.6201H6.31006V14.8101Z' />
												<path d='M3.5 17.63H0.689941V20.44H3.5V17.63Z' />
											</IconArrow>
										</Link>
									) : (
										<EmptyButton>
											<span>
												{item.availability === ''
													? item.name
													: item.availability}
											</span>
										</EmptyButton>
									)}
								</GearItem>
						  ))
						: filterProducts.map(item => (
								<GearItem
									key={item._id}
									absent={item.availability === 'soon' ? true : false}
									onClick={() => handleClick(item._id, item.name)}
								>

									<GearCover
										activeitem={item._id === activeitem ? true : false}
									>

										<Image
											src={item.imgGear?.replace('http:', 'https:')}
											alt='Nerv'
										/>
										<GearLineInfoFirst>
											<GearName>{item.name}</GearName>
											<TotalPrice price={item.price} priceWithDiscount={item.priceWithDiscount}  mainEventDiscount={mainEvent.active ? mainEvent.discont : undefined} />

										</GearLineInfoFirst>
										<GearLineInfoSecond>{item.desc}</GearLineInfoSecond>
									</GearCover>
									{item.availability.toLowerCase() === 'new' ||
									item.availability === '' ? (
										<Link

											style={{display:'none'}}

											to={`/interface/${item.name
												.replace(/ /gi, '-')
												.toLowerCase()}`}
										>
											<span>{item.name}</span>
											<span>${item.price}</span>
											<IconArrow viewBox='0 0 12 21'>
												<path d='M3.5 0.75H0.689941V3.56H3.5V0.75Z' />
												<path d='M6.31006 3.56006H3.5V6.37006H6.31006V3.56006Z' />
												<path d='M9.12988 6.38H6.31982V9.19H9.12988V6.38Z' />
												<path d='M11.9398 9.18994H9.12988V11.9999H11.9398V9.18994Z' />
												<path d='M9.12988 12H6.31982V14.81H9.12988V12Z' />
												<path d='M6.31006 14.8101H3.5V17.6201H6.31006V14.8101Z' />
												<path d='M3.5 17.63H0.689941V20.44H3.5V17.63Z' />
											</IconArrow>
										</Link>
									) : (
										<EmptyButton>
											<span>
												{item.availability === ''
													? item.name
													: item.availability}
											</span>
										</EmptyButton>
									)}
								</GearItem>
						  ))}
				</GearBlock>
			</GearMain>
		</GearWrapper>
	);
};
